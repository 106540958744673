import {React, useState, useEffect} from 'react'
import './navbar.css'
import ibs_smc_kaist_logo from "../pages/static/ibs_logo_header_fix_fix.png"
import globe from "../static/globe.png"
import {isMobile} from 'react-device-detect';

export default function Navbar(){
    const count = JSON.parse(localStorage.getItem('count'));
    const linkSets = [
        {
            name: count?'home':'홈',
            className: 'home',
            link: '/',
        },
        {
            name: count?'predict':'예측',
            className: 'predict',
            link: '/#/predict_sleep',
        }
    ]
    const navigateLink = (link) =>{
        window.location.href = link
    }

    const globeClick = () => {
        const curCount = JSON.parse(localStorage.getItem('count'));
        console.log(curCount)
        if(curCount > 0){
            localStorage.setItem('count', JSON.stringify(0));
            window.location.reload()
        }else{
            localStorage.setItem('count', JSON.stringify(1));
            window.location.reload()
        }
    }

    return(
        <nav>
            <div className='navbar-title'>
                {/* <p onClick={()=>navigateLink('/')}>Sleep Disorder Predict</p> */}
                <img height="50px" src={ibs_smc_kaist_logo} onClick={()=>navigateLink('/')}/>
            </div>
            <div className='navbar-links'>
                {linkSets.map((val, id) =>
                    <div style={{padding: "25px"}} className={`${val.className}-link navbar-link`} key = {id} onClick={()=>navigateLink(val.link)}>
                        <p>{val.name}</p>
                    </div>
                )}

                <div className='globe'>
                    <img height="25px" width="25px" src={globe} onClick={globeClick}/>
                </div>
            </div>
        </nav>
    )
}