import {
  InputAdornment,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Radio,
  Button,
  Menu,
} from "@material-ui/core";
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateField } from '@mui/x-date-pickers/DateField'
import { useSelector } from "react-redux";
import CSRFToken from './csrf_token';
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import koLocale from "i18n-iso-countries/langs/ko.json";
import { makeStyles } from '@material-ui/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import {isMobile} from 'react-device-detect';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    ochre: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
  },
});

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
  color:"#fff",
  opacity:"0.3",
  "&.Mui-selected": {
   color: "#fff",
   opacity: '1.0',
   backgroundColor: "#323339",
    "&:hover": {
     color: "#fff",
     opacity: '1.0',
     backgroundColor: "#323339",
    },
  },
  "&:hover": {
   color: "#fff",
   opacity: '1.0',
   backgroundColor: "#323339",
 },
}));

const useStyles = makeStyles({
  select: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#323339',
     },
  },
  icon: {
      fill: 'white',
  },
  root: {
      background: '#323339',
      "& .MuiOutlinedInput-input": {
        color: "white",
        opacity: "0.6"
      },
      "& .MuiDateField-root": {
        color:'white'
      }
  },
  adornment: {
    backgroundColor: "white",
    
  },
})

export default function FormUserInfo({ formData, setFormData, step, setStep }) {

  const classes = useStyles()

  const count = JSON.parse(localStorage.getItem('count'));
  const ref1 = React.useRef(null);
  const goNext = (e) => {
    e.preventDefault();
    if(error != null){
      ref1.current.scrollIntoView(
        {behavior: 'auto',
        block: 'center',
        inline: 'center'}
        )
    }else{
      setStep(step + 1);
    }
  };

  countries.registerLocale(enLocale);
  countries.registerLocale(koLocale);

  var countryobj = countries.getNames ("en", { select: "official" });
  var countryObjArr = Object.entries(countryobj).reduce(function(sum, product) {
    if(product.value != 'South Korea'){
     sum.push(product);
    }
    return sum;
   }, []);
  const countryArr =  countryObjArr.map (([key , value]) => {
    return { label: value,
      value: key
    };
  });

  var countryobjkor = countries. getNames ("ko", { select: "official" });
  var countryObjKorArr = Object.entries(countryobjkor).reduce(function(sum, product) {
    if(product.value != '대한민국'){
     sum.push(product);
    }
    return sum;
   }, []);
  const countryArrKor = countryObjKorArr.map (([key , value]) => {
    return { label: value,
    value: key
    };
  });

  const [value, setValue] = React.useState();
  const [error, setError] = React.useState(null);
  // const [year, setYear] = React.useState(Date.getFullYear());
  // const [month, setMonth] = React.useState(Date.getMonth());
  // const [date, setDate] = React.useState(Date.getDate());
  const handleChange = (newValue) => {
    let date = newValue.$D
    let month = newValue.$M+1
    let year = newValue.$y
    //calculate age
    let today = new Date()
    let today_date = today.getDate()
    let today_month = today.getMonth()+1
    let today_year = today.getFullYear()

    let age = today_year - year - 1
    if(month <= today_month && date <= today_date){
      age = age + 1
    }
    console.log(age)
    setFormData({ ...formData, age: age });
    setValue(newValue);
  };
  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate':
      case 'minDate':
      case 'invalidDate': {
        return count?'Your date is not valid':'생년월일을 잘못 입력하셨어요!';
      }

      default: {
        return '';
      }
    }
  }, [error]);
  return (
    <>
      <form
        onSubmit={(e) => {
          goNext(e);
        }}
        marginTop="30px"
      >
        <CSRFToken />
        {isMobile?
        (
        <table>
          <tr>
            <td><label className="font-control" style={{paddingRight: '40px'}}>{count?"Date of birth: ": "생년월일: "}</label></td>
          </tr>
          <tr>
            <td>
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{color:'white'}}>
              <DateField
                required
                disableFuture
                ref={ref1}
                variant="outlined"
                sx={{background: '#323339', borderColor: 'white', width: '200px', input:{color:'white'}}}
                slotProps={{
                  textField: {
                    helperText: errorMessage,
                  },

                }}
                value={value}
                onChange={handleChange}
                onError={(newError) => {setError(newError);console.log("ERROR: "+error)}}
              />
              </LocalizationProvider>
              </td>
          </tr>
          <tr>
          <td>
        <label className="font-control" style={{marginTop: "30px"}}>{count?"Nationality: ":"국적: "}</label>
        </td>
        </tr>
        <tr>
        <td>
          <Select
            displayEmpty 
            // placeholder="required"
            variant="outlined"
            style={{ width: "200px", marginTop: "20px", color:'white' }}
            className={classes.select}
            inputProps={{
                classes: {
                    icon: classes.icon,
                    root: classes.root,
                },
            }}
            value={formData.nationality}
            onChange={(e) => {
              setFormData({...formData, nationality: e.target.value})
            }}
          >
            {count?
              <MenuItem key={'KR'} value={'South Korea'}>
                South Korea
              </MenuItem>:
              <MenuItem key={'KR'} value={'대한민국'}>
                대한민국
              </MenuItem>
            }
            {count?(!!countryArr?. length && countryArr.map (({ label, value }) => (
              <MenuItem key={value} value={value}>
              {label}
              </ MenuItem>
            ))):(!!countryArrKor?. length && countryArrKor.map (({ label, value }) => (
              <MenuItem key={value} value={value}>
              {label}
              </ MenuItem>
            )))}
          </Select>
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control" style={{marginTop: "30px"}}>{count?"Height: ":"키: "}</label>
        </td>
        </tr>
        <tr>
        <td>
          <TextField
            required
            type="number"
            defaultValue="Required"
            variant="outlined"
            className={classes.select}
            InputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
            },
              endAdornment: (
                <InputAdornment position="start"><p style={{color: "white", opacity:"0.6"}}>cm</p></InputAdornment>
              ),
            }}
            style={{ width: "200px", marginTop: "20px" }}
            step="0.01"
            value={formData.height}
            onChange={(e) => {
              setFormData({ ...formData, height: e.target.value });
            }}
          />
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control">{count?"Weight: ":"몸무게: "}</label>
        </td>
        </tr>
        <tr>
        <td>
          <TextField
            required
            type="number"
            defaultValue="Required"
            variant="outlined"
            className={classes.select}
            InputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
            },
              endAdornment: (
                <InputAdornment position="start" ><p style={{color: "white", opacity: "0.6"}}>kg</p></InputAdornment>
              ),
            }}
            style={{ width: "200px", marginTop: "20px"}}
            step="0.01"
            value={formData.weight}
            onChange={(e) => {
              setFormData({ ...formData, weight: e.target.value });
            }}
          />
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control">{count?"Sex: ":"성별: "}</label>
        </td>
        </tr>
        <tr>
        <td>
        <ToggleButtonGroup
          fullWidth
            value={formData.sex}
            exclusive
            onChange={(e) => {
              if(e.target.value != null){
                setFormData({ ...formData, sex: parseInt(e.target.value) });
              }
            }}
            aria-label="text alignment"
            sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '200px', marginTop: "20px"}}
          >
            <CustomToggleButton value={0} aria-label="left aligned" color="primary">
              남성
            </CustomToggleButton>
            <CustomToggleButton value={1} aria-label="right aligned" color="primary">
              여성
            </CustomToggleButton>
          </ToggleButtonGroup>
          {/* <FormControl>
            <RadioGroup
              row
              name="Gender"
              value={formData.sex}
              sx={{
                color: "red",
                "&.Mui-checked": {
                  color: "green"
                }
              }}
              className={classes.select}
              inputProps={{
                  classes: {
                      icon: classes.icon,
                      root: classes.root,
                  },
              }}
              style={{ marginTop: "20px", marginBottom: "30px", color: 'white' }}
              onChange={(e) => {
                setFormData({ ...formData, sex: e.target.value });
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio required={true} />}
                label={count?"Male":"남성"}
              />
              <FormControlLabel
                value={1}
                control={<Radio required={true} />}
                label={count?"Female":"여성"}
              />
            </RadioGroup>
          </FormControl> */}
          </td>
          </tr>
          </table>
        ):
        (
          <table>
          <tr>
            <td>
              <label className="font-control" style={{paddingRight: '40px'}}>{count?"Date of birth: ": "생년월일: "}</label>
            </td>
            <td>
              <LocalizationProvider dateAdapter={AdapterDayjs} style={{color:'white'}}>
              <DateField
                required
                disableFuture
                ref={ref1}
                variant="outlined"
                sx={{background: '#323339', borderColor: 'white', width: '700px', input:{color:'white'}}}
                slotProps={{
                  textField: {
                    helperText: errorMessage,
                  },

                }}
                value={value}
                onChange={handleChange}
                onError={(newError) => {setError(newError);console.log("ERROR: "+error)}}
              />
              </LocalizationProvider>
              </td>
          </tr>
        <tr>
          <td>
        <label className="font-control" style={{marginTop: "30px"}}>{count?"Nationality: ":"국적: "}</label>
        </td>
        <td>
          <Select
            displayEmpty 
            // placeholder="required"
            variant="outlined"
            style={{ width: "700px", marginTop: "20px", color:'white' }}
            className={classes.select}
            inputProps={{
                classes: {
                    icon: classes.icon,
                    root: classes.root,
                },
            }}
            value={formData.nationality}
            onChange={(e) => {
              setFormData({...formData, nationality: e.target.value})
            }}
          >
            {count?
              <MenuItem key={'KR'} value={'South Korea'}>
                South Korea
              </MenuItem>:
              <MenuItem key={'KR'} value={'대한민국'}>
                대한민국
              </MenuItem>
            }
            {count?(!!countryArr?. length && countryArr.map (({ label, value }) => (
              <MenuItem key={value} value={value}>
              {label}
              </ MenuItem>
            ))):(!!countryArrKor?. length && countryArrKor.map (({ label, value }) => (
              <MenuItem key={value} value={value}>
              {label}
              </ MenuItem>
            )))}
          </Select>
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control" style={{marginTop: "30px"}}>{count?"Height: ":"키: "}</label>
        </td>
        <td>
          <TextField
            required
            type="number"
            defaultValue="Required"
            variant="outlined"
            className={classes.select}
            InputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
            },
              endAdornment: (
                <InputAdornment position="start"><p style={{color: "white", opacity:"0.6"}}>cm</p></InputAdornment>
              ),
            }}
            style={{ width: "700px", marginTop: "20px" }}
            step="0.01"
            value={formData.height}
            onChange={(e) => {
              setFormData({ ...formData, height: e.target.value });
            }}
          />
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control">{count?"Weight: ":"몸무게: "}</label>
        </td>
        <td>
          <TextField
            required
            type="number"
            defaultValue="Required"
            variant="outlined"
            className={classes.select}
            InputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
            },
              endAdornment: (
                <InputAdornment position="start" ><p style={{color: "white", opacity: "0.6"}}>kg</p></InputAdornment>
              ),
            }}
            style={{ width: "700px", marginTop: "20px"}}
            step="0.01"
            value={formData.weight}
            onChange={(e) => {
              setFormData({ ...formData, weight: e.target.value });
            }}
          />
          </td>
          </tr>
          <tr>
            <td>
        <label className="font-control">{count?"Sex: ":"성별: "}</label>
        </td>
        <td>
        <ToggleButtonGroup
          fullWidth
            value={formData.sex}
            exclusive
            onChange={(e) => {
              if(e.target.value != null){
                setFormData({ ...formData, sex: parseInt(e.target.value) });
              }
            }}
            aria-label="text alignment"
            sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '700px', marginTop: "20px"}}
          >
            <CustomToggleButton value={0} aria-label="left aligned" color="primary">
              남성
            </CustomToggleButton>
            <CustomToggleButton value={1} aria-label="right aligned" color="primary">
              여성
            </CustomToggleButton>
          </ToggleButtonGroup>
          {/* <FormControl>
            <RadioGroup
              row
              name="Gender"
              value={formData.sex}
              sx={{
                color: "red",
                "&.Mui-checked": {
                  color: "green"
                }
              }}
              className={classes.select}
              inputProps={{
                  classes: {
                      icon: classes.icon,
                      root: classes.root,
                  },
              }}
              style={{ marginTop: "20px", marginBottom: "30px", color: 'white' }}
              onChange={(e) => {
                setFormData({ ...formData, sex: e.target.value });
              }}
            >
              <FormControlLabel
                value={0}
                control={<Radio required={true} />}
                label={count?"Male":"남성"}
              />
              <FormControlLabel
                value={1}
                control={<Radio required={true} />}
                label={count?"Female":"여성"}
              />
            </RadioGroup>
          </FormControl> */}
          </td>
          </tr>
          </table>
        )}
        <Button
          type="submit"
          variant="outlined"
          style={{backgroundColor: '#3680F7', width: "150px", fontSize: "20px", marginBottom: "100px", color:"white", borderColor:"white", marginTop: "60px", marginLeft: isMobile?'0%':'77%'}}
        >
          {count?"Next":"다음"}
        </Button>
        
        
      </form>
    </>
  );
}
