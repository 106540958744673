import React from 'react';
import ReactDOM from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react'

import { Provider } from 'react-redux';
import store from './redux/configureStore'
// import store from './store';
import reportWebVitals from './reportWebVitals';
import { persistStore } from 'redux-persist'

import App from './App';
import './index.css';
import ReactGA from "react-ga4";

let persistor = persistStore(store);
ReactGA.initialize('G-XRSTWJK1CY');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}> */}
        <App />
      {/* </PersistGate>
    </Provider> */}
  </React.StrictMode>
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

reportWebVitals(SendAnalytics);
