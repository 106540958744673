import MainRoute from "./routes/MainRoute";
import "react-bootstrap/dist/react-bootstrap.min.js";

function App() {

  return (
    <MainRoute/>
  );
}

export default App;
