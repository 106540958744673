import React, { useEffect } from 'react'
import {HashRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import Navbar from '../core/Navbar'
import Home from '../pages/Home'
import Predict from '../pages/Predict'
import Detail from '../pages/Detail'
import Result from '../pages/components/Result'

export default function MainRoute(){
    useEffect(() => {
        document.title = 'SLEEPS';
      }, []);
    return(
        <Router>
            <header>
                <Navbar/>
            </header>
            <Routes>
                <Route exact path="" element={<Home/>}/>
                <Route exact path="/predict_sleep" element={<Predict/>}/>
                <Route exact path="/detail" element={<Detail/>}/>
                <Route exact path="/result" element={<Result/>}/>
                {/* <Route exact path="/" element={<Navigate to="sleep-prediction" />}/> */}
            </Routes>
        </Router>
    )
}