import "../static/result.css";
import { useNavigate } from "react-router-dom";
import RadarChart from "react-svg-radar-chart";
import Chart from "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import "react-svg-radar-chart/build/css/index.css";
import "./static/horizontal.css";
import { Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import insomnia from '../static/insomnia.jpg'
import osa from '../static/osa.jpeg'
import comisa from '../static/comisa.jpeg'
import { useSelector } from "react-redux";
import {isMobile} from 'react-device-detect';
import React, {useEffect, useRef, useState} from 'react';
import { getImages } from "../functions_utils";
import axios from "axios";
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/styles';
import {InputAdornment} from '@material-ui/core';
import "./static/spinner.css";

const useStyles = makeStyles({
	select: {
		'& .MuiOutlinedInput-notchedOutline': {
		  borderColor: '#323339',
	   },
	},
	icon: {
		fill: 'white',
	},
	root: {
		background: '#323339',
		"& .MuiOutlinedInput-input": {
		  color: "white",
		  opacity: "0.6"
		},
		"& .MuiDateField-root": {
		  color:'white'
		}
	},
	adornment: {
	  backgroundColor: "white",
	  
	},
  })

export default function Result() {
	const classes = useStyles()
	const count = JSON.parse(localStorage.getItem('count'));
	let navigate = useNavigate();

	const { state } = useLocation();
	const routeChange = (res) => {
		let path = "/detail";
		navigate(path, { state: {res}});
	};
	let sex = state.res["sex"];
	let age = state.res["age"];
	let weight = state.res["weight"];
	let insomniaResult = state.res["insomniaResult"];
	let OSAResult = state.res["OSAResult"];
	let COMISAResult = state.res["COMISAResult"];
	let insomniaResultAge = state.res["insomniaResultAge"];
	let OSAResultAge = state.res["OSAResultAge"];
	let COMISAResultAge = state.res["COMISAResultAge"];

	const data_insomnia = [];
	const data_osa = [];
	const data_comisa = [];

	const data_insomnia_age = [];
	const data_osa_age = [];
	const data_comisa_age = [];

	//   console.log("DATA:", insomniaResult);
	//   console.log("DATA:", OSAResult);
	//   console.log("DATA:", COMISAResult);

	for (let i = 0; i < 11; i++) {
		data_insomnia.push(insomniaResult[i]["insomnia_probability"] * 100);
		data_osa.push(OSAResult[i]["osa_probability"] * 100);
		data_comisa.push(COMISAResult[i]["comisa_probability"] * 100);

		data_insomnia_age.push(insomniaResultAge[i]["insomnia_probability"] * 100);
		data_osa_age.push(OSAResultAge[i]["osa_probability"] * 100);
		data_comisa_age.push(COMISAResultAge[i]["comisa_probability"] * 100);

	}

	// const data = [
	// 	{
	// 		data: {
	// 			Insomnia: insomniaResult[5]["insomnia_probability"],
	// 			OSA: OSAResult[5]["osa_probability"],
	// 			COMISA: COMISAResult[5]["comisa_probability"],
	// 		},
	// 		meta: { color: "red" },
	// 	},
	// ];
	// const captions = {
	// 	Insomnia: "Insomnia",
	// 	OSA: "OSA",
	// 	COMISA: "COMISA",
	// };

	let data = [{
		name: count?"Insomnia":"만성불면증 ",
		prob: insomniaResult[5]["insomnia_probability"],
	},
	{
		name: count?"OSA":"수면호흡장애",
		prob: OSAResult[5]["osa_probability"],
	},
	{
		name: count?"COMISA":["수면호흡장애를","동반한 불면증"],
		prob: COMISAResult[5]["comisa_probability"],
	},]
	
	let list = data.sort(function(x, y) {
		if (x["prob"] == y["prob"])
			return 0;
		return x["prob"] > y["prob"] ? -1:1;
	})

	const options_bar = {
		responsive: true,
		plugins: {
		  	legend: {
				display: false,
		  	},
		  	title: {
				display: false,
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:20,
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size: isMobile?10:20
					},
					color: 'white'
				},
				suggestedMin: Math.max(0, data_insomnia[5] - 5),
				suggestedMax: Math.min(100, data_insomnia[5] + 5),
			},
			xAxis:{
				ticks:{
					font:{
						size: isMobile?5:20
					},
					color: 'white'
				},
				gridLines: {
					offsetGridLines : true
				}
			}
		},
	};

	const data_bar = {
		labels : [list[0]["name"], list[1]["name"], list[2]["name"]],
		datasets: [
			{
				data: [list[0]["prob"]*100, list[1]["prob"]*100, list[2]["prob"]*100],
				backgroundColor: "#3680F7",
				datalabels: {
					labels:{
						anchor: "end"
					}
				}
			}
		]
	}


	const insomnia_element = isMobile?
	(
		<div id="container">
			<img src={insomnia} style={{width:"50%", height:"50%", marginBottom:"30px", marginTop:"70px"}}/>
				
			<h2 className="h2" style={{fontSize:"22px"}}>
				{count?"Insomnia: ":"만성불면증 : "}{Math.round(data_insomnia[5] * 100) / 100}%
			</h2>
			<p className="p" style={{paddingLeft:"15px", paddingRight:"15px", fontSize:"15px"}}>
				{count?
				"Insomnia is a common sleep disorder. You may have trouble falling asleep, staying asleep, or getting good quality sleep with insomnia.":
				"잠들기 어렵거나, 자주 깨거나, 너무 일찍 깨서 잠들기 어려운 증상이 반복되는 경우에 만성불면증 이 의심됩니다."}
			</p>
			<Button
				onClick={() => {
					const data = {
						"id": 1,
						"sex": sex,
						"age": age,
						"weight": weight,
						"insomniaResult": insomniaResult,
						"OSAResult": OSAResult,
						"COMISAResult": COMISAResult,
						"insomniaResultAge": insomniaResultAge,
						"OSAResultAge": OSAResultAge,
						"COMISAResultAge": COMISAResultAge,
					}
					routeChange(data);
				}}
				type="submit"
				variant="outlined"
				style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7' }}
			>
				{count?"Read more":"자세히 보기"}
			</Button>
		</div>
	)
	:
	(
		<table>
			<tr><td colSpan={2}><h2 className="h2" style={{paddingTop:"40px"}}>
					<b>{count?"Insomnia: ":"만성불면증: "}{Math.round(data_insomnia[5] * 100) / 100}%</b>
				</h2>
				</td>
				</tr>
			<tr>
				<td>
				<img src={insomnia} style={{objectFit:"cover", width:"200px", height:"200px", marginBottom:"30px", paddingRight: "20px"}}/>
				</td>
				{/* <Line options={options_weight_insomnia} data={insomnia_data_weight} /> */}
				
				<td>
				<p className="p">
					{count?
					"Insomnia is a common sleep disorder. You may have trouble falling asleep, staying asleep, or getting good quality sleep with insomnia.":
					"잠들기 어렵거나, 자주 깨거나, 너무 일찍 깨서 잠들기 어려운 증상이 반복되는 경우에 만성불면증 이 의심됩니다."}
				</p>
				<Button
					onClick={() => {
						const data = {
							"id": 1,
							"sex": sex,
							"age": age,
							"weight": weight,
							"insomniaResult": insomniaResult,
							"OSAResult": OSAResult,
							"COMISAResult": COMISAResult,
							"insomniaResultAge": insomniaResultAge,
							"OSAResultAge": OSAResultAge,
							"COMISAResultAge": COMISAResultAge,
						}
						routeChange(data);
					}}
					type="submit"
					variant="outlined"
					style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7' }}
				>
					{count?"Read more":"자세히 보기"}
				</Button>
				</td>
			</tr>
		</table>
	);

	const comisa_element = 
	isMobile?
	(
		<div id="container">
			<img src={comisa} style={{width:"50%", height:"50%", marginBottom:"30px", marginTop:"70px"}}/>
			<h2 className="h2" style={{fontSize:"22px"}}>
				{count?"COMISA: ":"수면호흡장애를 동반한 불면증: "}{Math.round(data_comisa[5] * 100) / 100}%
			</h2>
			<p className="p" style={{paddingLeft:"15px", paddingRight:"15px", fontSize:"15px"}}>
				{count?
				"Co-morbid insomnia and sleep apnea (COMISA) is a highly prevalent sleep disorder, which results in impairments to sleep, daytime functioning, and quality of life.":
				"수면호흡장애를 동반한 불면증은 수면과 주간 기능에 손상을 일으키고, 삶의 질을 저하시키는 흔한 수면 장애입니다."}
			</p>
			<Button
				onClick={() => {
					const data = {
						"id": 2,
						"sex":sex,
						"age": age,
						"weight": weight,
						"insomniaResult": insomniaResult,
						"OSAResult": OSAResult,
						"COMISAResult": COMISAResult,
						"insomniaResultAge": insomniaResultAge,
						"OSAResultAge": OSAResultAge,
						"COMISAResultAge": COMISAResultAge,
					}
					routeChange(data);
				}}
				type="submit"
				variant="outlined"
				style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7' }}
			>
				{count?"Read more":"자세히 보기"}
			</Button>
		</div>
	)
	:
	(
		<table>
			<tr><td colSpan={2}><h2 className="h2" style={{paddingTop: "40px"}}>
					<b>{count?"COMISA: ":"수면호흡장애를 동반한 불면증: "}{Math.round(data_comisa[5] * 100) / 100}%</b>
				</h2>
				</td>
				</tr>
				<tr>
					<td>
				<img src={comisa} style={{objectFit:"cover", width:"200px", height:"200px", paddingRight:"20px"}}/>
				{/* <Line options={options_weight_comisa} data={comisa_data_weight} /> */}
				</td>
				<td>
				<p className="p">
					{count?
					"Co-morbid insomnia and sleep apnea (COMISA) is a highly prevalent sleep disorder, which results in impairments to sleep, daytime functioning, and quality of life.":
					"수면호흡장애를 동반한 불면증은 수면과 주간 기능에 손상을 일으키고, 삶의 질을 저하시키는 흔한 수면 장애입니다."}
				</p>
				<Button
					onClick={() => {
						const data = {
							"id": 2,
							"sex":sex,
							"age": age,
							"weight": weight,
							"insomniaResult": insomniaResult,
							"OSAResult": OSAResult,
							"COMISAResult": COMISAResult,
							"insomniaResultAge": insomniaResultAge,
							"OSAResultAge": OSAResultAge,
							"COMISAResultAge": COMISAResultAge,
						}
						routeChange(data);
					}}
					type="submit"
					variant="outlined"
					style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7' }}
				>
					{count?"Read more":"자세히 보기"}
				</Button>
				</td>
				</tr>

		</table>
	);

	const osa_element = 
	isMobile?
	(
		<div id="container">
			<img src={osa} style={{width:"50%", height:"50%", marginBottom:"30px", marginTop:"70px"}}/>
			<h2 className="h2" style={{fontSize:"22px"}}>
				<b>{count?"OSA: ":"수면호흡장애: "}{Math.round(data_osa[5] * 100) / 100}%</b>
			</h2>
			<p className="p" style={{paddingLeft:"15px", paddingRight:"15px", fontSize:"15px"}}>
				{count?`Obstructive sleep apnea (OSA) is a chronic disease that occur snoring or 
				respiratory disorders due to narrowing of the upper airway through which air flows during sleep.`
				:"수면호흡장애 (폐쇄성 수면 무호흡증)는 수면 중에 공기가 소통하는 상기도가 좁아져서 코골이 또는 호흡장애가 발생하는 만성질환입니다."}
			</p>
			<Button
				onClick={() => {
					const data = {
						"id": 3,
						"sex":sex,
						"age": age,
						"weight": weight,
						"insomniaResult": insomniaResult,
						"OSAResult": OSAResult,
						"COMISAResult": COMISAResult,
						"insomniaResultAge": insomniaResultAge,
						"OSAResultAge": OSAResultAge,
						"COMISAResultAge": COMISAResultAge,
					}
					routeChange(data);
				}}
				type="submit"
				variant="outlined"
				style={{ width: "150px", textTransform: 'none', color: 'white', backgroundColor: '#3680F7'}}
			>
				{count?"Read more":"자세히 보기"}
			</Button>
		</div>
	)
	:
	(
		<table>
			<tr><td colSpan={2}>
			<h2 className="h2" style={{paddingTop:"40px"}}>
					<b>{count?"OSA: ":"수면호흡장애: "}{Math.round(data_osa[5] * 100) / 100}%</b>
				</h2>
				</td>
				</tr>
				<tr>
					<td>
				<img src={osa} style={{objectPosition:"0 0", objectFit:"cover", width:"200px", height:"200px", paddingRight: "20px"}}/>
				</td>
				{/* <Line options={options_weight_osa} data={osa_data_weight} /> */}
				<td>
				<p className="p">
					{count?`Obstructive sleep apnea (OSA) is a chronic disease that occur snoring or 
					respiratory disorders due to narrowing of the upper airway through which air flows during sleep.`
					:"수면호흡장애 (폐쇄성 수면 무호흡증)는 수면 중에 공기가 소통하는 상기도가 좁아져서 코골이 또는 호흡장애가 발생하는 만성질환입니다."}
				</p>
				<Button
					onClick={() => {
						const data = {
							"id": 3,
							"sex":sex,
							"age": age,
							"weight": weight,
							"insomniaResult": insomniaResult,
							"OSAResult": OSAResult,
							"COMISAResult": COMISAResult,
							"insomniaResultAge": insomniaResultAge,
							"OSAResultAge": OSAResultAge,
							"COMISAResultAge": COMISAResultAge,
						}
						routeChange(data);
					}}
					type="submit"
					variant="outlined"
					style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7' }}
				>
					{count?"Read more":"자세히 보기"}
				</Button>
				</td>
				</tr>
		</table>
	);

	const insomnia_osa_comisa = [insomnia_element, osa_element, comisa_element];
	const insomnia_comisa_osa = [insomnia_element, comisa_element, osa_element];

	const osa_insomnia_comisa = [osa_element, insomnia_element, comisa_element];
	const osa_comisa_insomnia = [osa_element, comisa_element, insomnia_element];

	const comisa_insomnia_osa = [comisa_element, insomnia_element, osa_element];
	const comisa_osa_insomnia = [comisa_element, osa_element, insomnia_element];

	const [userEmail, setUserEmail] = useState("");
	const [sendEmailClicked, setSendEmailClicked] = useState(0);

	const chartRef = useRef(null)
	const chartRef2 = useRef(null)
	const chartRef3 = useRef(null)
	const chartRef4 = useRef(null)
	const chartRef5 = useRef(null)
	const chartRef6 = useRef(null)
	const chartRef7= useRef(null)
	const detail = getImages(state.res);

	useEffect(() => {
		const ctx = document.getElementById('myChart');
		const myBarChart = new Chart(ctx, {
			type: 'bar',
			data: data_bar,
			options: options_bar
		});
		chartRef.current = myBarChart;

		const ctx2 = document.getElementById('myChart2');
		const ctx3 = document.getElementById('myChart3');
		const ctx4 = document.getElementById('myChart4');
		const ctx5 = document.getElementById('myChart5');
		const ctx6 = document.getElementById('myChart6');
		const ctx7 = document.getElementById('myChart7');
		
		const InsomniaChart = new Chart(ctx2, {
			type: 'line',
			data: detail["insomnia_data_age"],
			options: detail["options_age_insomnia"]
		})

		const InsomniaChart2 = new Chart(ctx3, {
			type: 'line',
			data: detail["insomnia_data_weight"],
			options: detail["options_weight_insomnia"]
		})

		const OSAChart = new Chart(ctx4, {
			type: 'line',
			data: detail["osa_data_age"],
			options: detail["options_age_osa"]
		})

		const OSAChart2 = new Chart(ctx5, {
			type: 'line',
			data: detail["osa_data_weight"],
			options: detail["options_weight_osa"]
		})

		const COMISAChart = new Chart(ctx6, {
			type: 'line',
			data: detail["comisa_data_age"],
			options: detail["options_age_comisa"]
		})

		const COMISAChart2 = new Chart(ctx7, {
			type: 'line',
			data: detail["comisa_data_weight"],
			options: detail["options_weight_comisa"]
		})

		// console.log("NEW NEW", InsomniaChart.toBase64Image);
		chartRef2.current = InsomniaChart;
		chartRef3.current = InsomniaChart2;
		chartRef4.current = OSAChart;
		chartRef5.current = OSAChart2;
		chartRef6.current = COMISAChart;
		chartRef7.current = COMISAChart2;

        // Cleanup function to destroy the chart when component unmounts
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
			if (chartRef2.current) {
                chartRef2.current.destroy();
            }
			if (chartRef3.current) {
                chartRef3.current.destroy();
            }
			if (chartRef4.current) {
                chartRef4.current.destroy();
            }
			if (chartRef5.current) {
                chartRef5.current.destroy();
            }
			if (chartRef6.current) {
                chartRef6.current.destroy();
            }
			if (chartRef7.current) {
                chartRef7.current.destroy();
            }
        };
	}, []);

	const handleExport = () => {
		setSendEmailClicked(1);
		const base64Image1 = chartRef.current.toBase64Image();
		const base64Image2 = chartRef2.current.toBase64Image();
		const base64Image3 = chartRef3.current.toBase64Image();
		const base64Image4 = chartRef4.current.toBase64Image();
		const base64Image5 = chartRef5.current.toBase64Image();
		const base64Image6 = chartRef6.current.toBase64Image();
		const base64Image7 = chartRef7.current.toBase64Image();
		axios.post('https://www.sleep-math.com/predict/send_email/', {
			bar: base64Image1,
			insomnia_age: base64Image2,
			insomnia_weight: base64Image3,
			osa_age: base64Image4,
			osa_weight: base64Image5,
			comisa_age: base64Image6,
			comisa_weight: base64Image7,
			insomnia_prob: insomniaResult[5]["insomnia_probability"]*100,
			osa_prob: OSAResult[5]["osa_probability"]*100,
			comisa_prob: COMISAResult[5]["comisa_probability"]*100,
			email: userEmail,
			insomnia_graph_text: detail["insomnia_graph_text"],
			osa_graph_text: detail["osa_graph_text"],
			comisa_graph_text: detail["comisa_graph_text"],
			insomnia_graph_text1: detail["insomnia_graph_text1"],
			osa_graph_text1: detail["osa_graph_text1"],
			comisa_graph_text1: detail["comisa_graph_text1"],
		  })
		  .then(function (response) {
			console.log(response);
			setSendEmailClicked(2);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
    };

	return (
		<div style={{paddingBottom:"50px", paddingTop: '50px', backgroundColor: "black", paddingLeft: isMobile? "5%":"20%", paddingRight: isMobile?"20%":"20%"}}>
			{isMobile?
			<div style={{fontSize:"10px"}}>
			<h1 style={{marginTop:"30px", paddingRight:"10px", color:'white'}}>{count?"Risk prediction":"위험도 예측"}</h1>
			<Bar options={options_bar} data={data_bar}/>
			<p style={{marginBottom: "10px"}}>{count?
			"*If SLEEPS predicts the risk of ‘OSA’ as 90%, there is a 90% chance that OSA will be detected when actual polysomnography is performed."
			:"*SLEEPS가 ‘수면호흡장애’의 위험도를 90%로 예측했다면, 실제 수면다원검사를 시행했을 때, 수면호흡장애가 발견될 확률이 90%임을 의미합니다"}</p>
			<p style={{marginBottom: "40px"}}>{count?
			`*To see how the risk changes as your weight or age changes, click 'Read More'.`
			:"*'자세히 보기'를 누르시면 몸무게나 나이가 변화함에 따라 위험도가 어떻게 변화하는지 볼 수 있습니다."}</p>
			</div>
			:
			(
			<div>
			<h1 className="h1" style={{marginTop:"30px"}}>{count?"Risk prediction":"위험도 예측"}</h1>
			<p className="p" style={{marginBottom: "10px"}}>
				{count?"*If SLEEPS predicts the risk of ‘OSA’ as 90%, there is a 90% chance that OSA will be detected when actual polysomnography is performed.":
				"*SLEEPS가 ‘수면호흡장애’의 위험도를 90%로 예측했다면, 실제 수면다원검사를 시행했을 때, 수면호흡장애가 발견될 확률이 90%임을 의미합니다"}</p>
			<p className="p" style={{marginBottom: "40px"}}>{count?
			`*To see how the risk changes as your weight or age changes, click 'Read More'.`
			:"*'자세히 보기'를 누르시면 몸무게나 나이가 변화함에 따라 위험도가 어떻게 변화하는지 볼 수 있습니다."}</p>
			<center>
			<div style={{width:"80%", margin:"30px", marginBottom:"50px"}}>
			<Bar options={options_bar} data={data_bar} style={{fill: 'blue'}}/>
			</div>
			</center>
			</div>
			)
			}

			<div style={{ width: "600px", height: "300px", position: 'absolute', left: '-9999px', top: '-9999px' }}>
				<canvas id="myChart"></canvas>
				<canvas id="myChart2"></canvas>
				<canvas id="myChart3"></canvas>
				<canvas id="myChart4"></canvas>
				<canvas id="myChart5"></canvas>
				<canvas id="myChart6"></canvas>
				<canvas id="myChart7"></canvas>

			</div>
			
			{data_insomnia[5] > data_osa[5] &&
				data_insomnia[5] > data_osa[5] &&
				data_osa[5] > data_comisa[5]
				? insomnia_osa_comisa
				: data_insomnia[5] > data_osa[5] &&
					data_insomnia[5] > data_comisa[5] &&
					data_comisa[5] > data_osa[5]
					? insomnia_comisa_osa
					: data_osa[5] > data_insomnia[5] &&
						data_osa[5] > data_comisa[5] &&
						data_insomnia[5] > data_comisa[5]
						? osa_insomnia_comisa
						: data_osa[5] > data_insomnia[5] &&
							data_osa[5] > data_comisa[5] &&
							data_comisa[5] > data_insomnia[5]
							? osa_comisa_insomnia
							: data_comisa[5] > data_insomnia[5] &&
								data_comisa[5] > data_osa[5] &&
								data_osa[5] > data_insomnia[5]
								? comisa_osa_insomnia
								: comisa_insomnia_osa}

		{isMobile?
		<>
		{/*
				sendEmailClicked?
				(
					<center>
					{sendEmailClicked==1?
					(
						<div className="spinner-container" style={{marginTop: "15px"}}>
							<div className="loading-spinner">

							</div>
						</div>
					):
					(
						<p className="p" style={{marginTop: "15px"}}>{count?"Your result has been sent to your email!": "귀하의 결과는 귀하의 이메일로 전송되었습니다!"}</p>
					)
					}
					</center>
				)
				:(
					<>
					<p className="p" style={{marginTop: "15px"}}>{count?"Please write your e-mail address here, if you want to get a result in a pdf form": "결과지를 이메일로 전송받으시려면 이 칸에 이메일 주소를 적어주세요"}</p>
					<TextField
						required
						type="email"
						className={classes.select}
						InputProps={{
							classes: {
							icon: classes.icon,
							root: classes.root,
						},
						}}
						defaultValue="Required"
						variant="outlined"
						style={{ width: "200px"}}
						value={userEmail}
						onChange={(e) => {
						setUserEmail(e.target.value);
						}}
					/>
					<br/><br/>
					<Button
						onClick={handleExport}
						type="submit"
						variant="outlined"
						style={{ width: "100px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7'}}
					>
						{count?"Send":"결과지 전송"}
					</Button></>)
		*/
		}

		{count? (
			<p style={{fontSize:"18px", textAlign:"justify", color:"#808080", marginTop: "15px"}}>This algorithm is developed by <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS Biomedical Mathematics Group</a> and <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST Mathematical Biology Laboratory</a> based on the data collected at Samsung Medical Center.</p>) : 
			(<p style={{fontSize:"18px", textAlign:"justify", color:"#808080", marginTop: "15px"}}>본 알고리즘은 삼성서울병원에서 수집된 데이터를 바탕으로 <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS 의생명수학그룹</a> 및 <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST 수리생물학 연구실</a>에서 개발했습니다.</p>)}
		
		<p style={{fontSize:"18px", textAlign:"justify", color:"#808080"}}>
			{count? (
			`Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023.`) : 
				(`Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023.`)}
		</p>
		</>:
		<>
		{/* {
				sendEmailClicked?
				(
					<center>
					{sendEmailClicked==1?
					(
						<div className="spinner-container" style={{marginTop: "15px"}}>
							<div className="loading-spinner">

							</div>
						</div>
					):
					(
						<p className="p" style={{marginLeft: "230px", marginTop: "20px"}}>{count?"Your result has been sent to your email!": "귀하의 결과는 귀하의 이메일로 전송되었습니다!"}</p>
					)
					}
					</center>
				)
				:(
					<>
					<p className="p" style={{marginLeft: "100px", marginTop: "20px"}}>{count?"Please write your e-mail address here, if you want to get a result in a pdf form": "결과지를 이메일로 전송받으시려면 이 칸에 이메일 주소를 적어주세요"}</p>
					<TextField
						required
						type="email"
						className={classes.select}
						InputProps={{
							classes: {
							icon: classes.icon,
							root: classes.root,
						},
						}}
						defaultValue="Required"
						variant="outlined"
						style={{ width: "600px", marginLeft:"100px", marginRight:"100px"}}
						value={userEmail}
						onChange={(e) => {
						setUserEmail(e.target.value);
						}}
					/>
					<br/><br/>
					<Button
						onClick={handleExport}
						type="submit"
						variant="outlined"
						style={{ width: "150px", textTransform: 'none',color: 'white', backgroundColor: '#3680F7',  marginLeft:"315px", marginRight:"100px",}}
					>
						{count?"Send":"결과지 전송"}
					</Button></>)
			} */}

			{count? (
			<p style={{fontSize:"18px", padding:"15px", paddingRight:isMobile?"15px":"50px",textAlign:"justify", color:"#808080", marginLeft:"100px", marginRight:"100px", paddingTop: "50px"}}>This algorithm is developed by <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS Biomedical Mathematics Group</a> and <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST Mathematical Biology Laboratory</a> based on the data collected at Samsung Medical Center.</p>) : 
			(<p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", textAlign:"justify", color:"#808080", marginLeft:"100px", marginRight:"100px", paddingTop: "50px"}}>본 알고리즘은 삼성서울병원에서 수집된 데이터를 바탕으로 <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS 의생명수학그룹</a> 및 <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST 수리생물학 연구실</a>에서 개발했습니다.</p>)}
		
		<p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", textAlign:"justify", color:"#808080", marginLeft:"100px", marginRight:"100px"}}>
			{count? (
			`Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023.`) : 
				(`Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023.`)}
		</p>
		</>
		}

		
		</div>
	);
}
