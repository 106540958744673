import React from "react";
import { useLocation } from "react-router-dom";
import "../static/detail.css"
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import {isMobile} from 'react-device-detect';
import {insomnia0_mean, insomnia0_std, 
	insomnia1_mean, insomnia1_std,
	osa0_mean, osa0_std,
	osa1_mean, osa1_std,
	comisa0_mean, comisa0_std,
	comisa1_mean, comisa1_std} from "./components/Data";

export function getImages(res){
    const count = JSON.parse(localStorage.getItem('count'));
    let sex = res["sex"];
	let age = res["age"];
	let weight = res["weight"];
	let insomniaResult = res["insomniaResult"];
	let OSAResult = res["OSAResult"];
	let COMISAResult = res["COMISAResult"];
	let insomniaResultAge = res["insomniaResultAge"];
	let OSAResultAge = res["OSAResultAge"];
	let COMISAResultAge = res["COMISAResultAge"];

	const data_insomnia = [];
	const data_osa = [];
	const data_comisa = [];

	const data_insomnia_age = [];
	const data_osa_age = [];
	const data_comisa_age = [];

	const data_insomnia_age_mean = [];
	const data_osa_age_mean = [];
	const data_comisa_age_mean = [];

	const data_insomnia_std1 = [];
	const data_osa_std1 = [];
	const data_comisa_std1 = [];

	const data_insomnia_age_std1 = [];
	const data_osa_age_std1 = [];
	const data_comisa_age_std1 = [];

	const data_insomnia_age_mean_std1 = [];
	const data_osa_age_mean_std1 = [];
	const data_comisa_age_mean_std1 = [];

	const data_insomnia_std2 = [];
	const data_osa_std2 = [];
	const data_comisa_std2 = [];

	const data_insomnia_age_std2 = [];
	const data_osa_age_std2 = [];
	const data_comisa_age_std2 = [];

	const data_insomnia_age_mean_std2 = [];
	const data_osa_age_mean_std2 = [];
	const data_comisa_age_mean_std2 = [];

	const weight_label = [];
	const age_label = [];

	let new_weight_diff_insomnia = -1000;
	let new_weight_diff_osa = -1000;
	let new_weight_diff_comisa = -1000;
	let new_weight_diff_insomnia_percent = -1;
	let new_weight_diff_osa_percent = -1;
	let new_weight_diff_comisa_percent = -1;

	//   console.log("DATA:", insomniaResult);
	//   console.log("DATA:", OSAResult);
	//   console.log("DATA:", COMISAResult);
	for (let i = 0; i < 11; i++) {
		data_insomnia.push(insomniaResult[i]["insomnia_probability"] * 100);
		data_osa.push(OSAResult[i]["osa_probability"] * 100);
		data_comisa.push(COMISAResult[i]["comisa_probability"] * 100);

		data_insomnia_age.push(insomniaResultAge[i]["insomnia_probability"] * 100);
		data_osa_age.push(OSAResultAge[i]["osa_probability"] * 100);
		data_comisa_age.push(COMISAResultAge[i]["comisa_probability"] * 100);
		
		if(sex == 0){
			let idx0 = Math.min(age, 86)
			let insomnia_mean_temp = insomnia0_mean[idx0]
			let osa_mean_temp = osa0_mean[idx0]
			let comisa_mean_temp = comisa0_mean[idx0]

			let insomnia_std1_temp = insomnia_mean_temp + insomnia0_std[idx0]
			let insomnia_std2_temp = insomnia_mean_temp - insomnia0_std[idx0]
			let osa_std1_temp = osa_mean_temp + osa0_std[idx0]
			let osa_std2_temp = osa_mean_temp - osa0_std[idx0]
			let comisa_std1_temp = comisa_mean_temp + comisa0_std[idx0]
			let comisa_std2_temp = comisa_mean_temp - comisa0_std[idx0]

			data_insomnia_age_mean.push(insomnia_mean_temp * 100);
			data_osa_age_mean.push(osa_mean_temp * 100);
			data_comisa_age_mean.push(comisa_mean_temp * 100);

			data_insomnia_age_mean_std1.push(insomnia_std1_temp * 100);
			data_insomnia_age_mean_std2.push(insomnia_std2_temp * 100);
			data_osa_age_mean_std1.push(osa_std1_temp * 100);
			data_osa_age_mean_std2.push(osa_std2_temp * 100);
			data_comisa_age_mean_std1.push(comisa_std1_temp * 100);
			data_comisa_age_mean_std2.push(comisa_std2_temp * 100);
		}else{
			let idx1 = Math.min(age, 86)
			let insomnia_mean_temp = insomnia1_mean[idx1]
			let osa_mean_temp = osa1_mean[idx1]
			let comisa_mean_temp = comisa1_mean[idx1]

			let insomnia_std1_temp = insomnia_mean_temp + insomnia1_std[idx1]
			let insomnia_std2_temp = insomnia_mean_temp - insomnia1_std[idx1]
			let osa_std1_temp = osa_mean_temp + osa1_std[idx1]
			let osa_std2_temp = osa_mean_temp - osa1_std[idx1]
			let comisa_std1_temp = comisa_mean_temp + comisa1_std[idx1]
			let comisa_std2_temp = comisa_mean_temp - comisa1_std[idx1]

			data_insomnia_age_mean.push(insomnia_mean_temp * 100);
			data_osa_age_mean.push(osa_mean_temp * 100);
			data_comisa_age_mean.push(comisa_mean_temp * 100);

			data_insomnia_age_mean_std1.push(insomnia_std1_temp * 100);
			data_insomnia_age_mean_std2.push(insomnia_std2_temp * 100);
			data_osa_age_mean_std1.push(osa_std1_temp * 100);
			data_osa_age_mean_std2.push(osa_std2_temp * 100);
			data_comisa_age_mean_std1.push(comisa_std1_temp * 100);
			data_comisa_age_mean_std2.push(comisa_std2_temp * 100);
		}

		if(new_weight_diff_insomnia == -1000 && Math.abs(data_insomnia[i] - (insomniaResult[5]["insomnia_probability"] * 100)) >= 5){
			new_weight_diff_insomnia = i-5
			new_weight_diff_insomnia_percent = data_insomnia[i] - (insomniaResult[5]["insomnia_probability"] * 100)
		}
		if(new_weight_diff_osa == -1000 && Math.abs(data_osa[i] - (OSAResult[5]["osa_probability"] * 100)) >= 5){
			new_weight_diff_osa = i-5
			new_weight_diff_osa_percent = data_osa[i] - (OSAResult[5]["osa_probability"] * 100)
		}
		if(new_weight_diff_comisa == -1000 && Math.abs(data_comisa[i] - (COMISAResult[5]["comisa_probability"] * 100)) >= 5){
			new_weight_diff_comisa = i-5
			new_weight_diff_comisa_percent = data_comisa[i] - (COMISAResult[5]["comisa_probability"] * 100)
		}

		weight_label.push(weight);
		age_label.push(age);

		age = age + 1;
		weight = weight + 1;

		//add the std
		data_insomnia_std1.push(
			data_insomnia[i] - insomniaResult[i]["insomnia_std"] * 100
		);
		data_insomnia_std2.push(
			data_insomnia[i] + insomniaResult[i]["insomnia_std"] * 100
		);

		data_insomnia_age_std1.push(
			data_insomnia_age[i] - insomniaResult[i]["insomnia_std"] * 100
		);
		data_insomnia_age_std2.push(
			data_insomnia_age[i] + insomniaResult[i]["insomnia_std"] * 100
		);

		data_osa_std1.push(data_osa[i] - OSAResult[i]["osa_std"] * 100);
		data_osa_std2.push(data_osa[i] + OSAResult[i]["osa_std"] * 100);

		data_osa_age_std1.push(data_osa_age[i] - OSAResult[i]["osa_std"] * 100);
		data_osa_age_std2.push(data_osa_age[i] + OSAResult[i]["osa_std"] * 100);

		data_comisa_std1.push(data_comisa[i] - COMISAResult[i]["comisa_std"] * 100);
		data_comisa_std2.push(data_comisa[i] + COMISAResult[i]["comisa_std"] * 100);

		data_comisa_age_std1.push(
			data_comisa_age[i] - COMISAResult[i]["comisa_std"] * 100
		);
		data_comisa_age_std2.push(
			data_comisa_age[i] + COMISAResult[i]["comisa_std"] * 100
		);
	}

	// const data = [
	// 	{
	// 		data: {
	// 			Insomnia: insomniaResult[5]["insomnia_probability"],
	// 			OSA: OSAResult[5]["osa_probability"],
	// 			COMISA: COMISAResult[5]["comisa_probability"],
	// 		},
	// 		meta: { color: "red" },
	// 	},
	// ];
	// const captions = {
	// 	Insomnia: "Insomnia",
	// 	OSA: "OSA",
	// 	COMISA: "COMISA",
	// };
	let insomnia_graph_text1 = ""
	let osa_graph_text1 = ""
	let comisa_graph_text1 = ""
	if(new_weight_diff_insomnia > 0){
		if(new_weight_diff_insomnia_percent > 0){
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would increase by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = "체중이 " + new_weight_diff_insomnia + "kg 증가하면 불면증 위험이 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_insomnia_percent = Math.abs(new_weight_diff_insomnia_percent)
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would decrease by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = "체중이 " + new_weight_diff_insomnia + "kg 증가하면 불면증 위험이 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_insomnia = Math.abs(new_weight_diff_insomnia)
		if(new_weight_diff_insomnia_percent > 0){
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would increase by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = new_weight_diff_insomnia + "kg 감량하면 불면증 위험 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_insomnia_percent = Math.abs(new_weight_diff_insomnia_percent)
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would decrease by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = new_weight_diff_insomnia + "kg 감량하면 불면증 위험 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_osa > 0){
		if(new_weight_diff_osa_percent > 0){
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would increase by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = "체중이 " + new_weight_diff_osa + "kg 증가하면 수면호흡장애 위험이 " + new_weight_diff_osa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_osa_percent = Math.abs(new_weight_diff_osa_percent)
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would decrease by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = "체중이 " + new_weight_diff_osa + "kg 증가하면 수면호흡장애 위험이 " + new_weight_diff_osa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_osa = Math.abs(new_weight_diff_osa)
		if(new_weight_diff_osa_percent > 0){
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would increase by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = new_weight_diff_osa + "kg 감량하면 수면호흡장애 위험 "+ new_weight_diff_osa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_osa_percent = Math.abs(new_weight_diff_osa_percent)
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would decrease by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = new_weight_diff_osa + "kg 감량하면 수면호흡장애 위험 " + new_weight_diff_osa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_comisa > 0){
		if(new_weight_diff_comisa_percent > 0){
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would increase by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = "체중이 " + new_weight_diff_comisa + "kg 증가하면 수면호흡장애를 동반한 불면증 위험이 " + new_weight_diff_comisa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_comisa_percent = Math.abs(new_weight_diff_comisa_percent)
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would decrease by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = "체중이 " + new_weight_diff_comisa + "kg 증가하면 수면호흡장애를 동반한 불면증 위험이 " + new_weight_diff_comisa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_comisa = Math.abs(new_weight_diff_comisa)
		if(new_weight_diff_comisa_percent > 0){
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would increase by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = new_weight_diff_comisa + "kg 감량하면 수면호흡장애를 동반한 불면증 위험 "+ new_weight_diff_comisa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_comisa_percent = Math.abs(new_weight_diff_comisa_percent)
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would decrease by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = new_weight_diff_comisa + "kg 감량하면 수면호흡장애를 동반한 불면증 위험 "+ new_weight_diff_comisa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_insomnia == 1000){
		insomnia_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}
	if(new_weight_diff_osa == 1000){
		osa_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}
	if(new_weight_diff_comisa == 1000){
		comisa_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}

	const insomnia_graph_text = data_insomnia_age[5]-data_insomnia_age_mean[5]>0?(count?"Your insomnia risk is higher than the average person on your age":"당신의 불면증 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
									(count?"Your insomnia risk is lower than the average person on your age": "당신의 불면증 위험은 당신 나이대의 평균적인 사람보다 낮습니다");
	const osa_graph_text = data_osa_age[5]-data_osa_age_mean[5]>0?(count?"Your OSA risk is higher than the average person on your age":"당신의 수면호흡장애 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
	(count?"Your OSA risk is lower than the average person on your age": "당신의 수면호흡장애 위험은 당신 나이대의 평균적인 사람보다 낮습니다");
	const comisa_graph_text = data_comisa_age[5]-data_comisa_age_mean[5]>0?(count?"Your COMISA risk is higher than the average person on your age":"당신의 수면호흡장애를 동반한 불면증 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
	(count?"Your COMISA risk is lower than the average person on your age": "당신의 수면호흡장애를 동반한 불면증 위험은 당신 나이대의 평균적인 사람보다 낮습니다");

	const options_weight_insomnia = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"Insomnia risk depending on the weight change":"몸무게에 따른 만성불면증 위험도 변화",
				font: {
					size: isMobile?18:24,
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_weight_osa = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"OSA risk depending on the weight change":"몸무게에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_weight_comisa = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"COMISA risk depending on the weight change":"몸무게에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					font:{
						size:isMobile?10:16
					},
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					color: 'white'
				},
			},
		},
	};

	const insomnia_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_insomnia,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_insomnia_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_insomnia_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},

		],
	};
	const osa_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_osa,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_osa_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_osa_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
		],
	};
	const comisa_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_comisa,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_comisa_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_comisa_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
		],
	};

	const options_age_insomnia = {
		plugins: {
			legend: {
				// display: false
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"Insomnia risk depending on the age change":"나이에 따른 만성불면증 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Age (year)":"나이 (세)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_age_osa = {
		plugins: {
			legend: {
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"OSA risk depending on the age change":"나이에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					font:{
						size:isMobile?10:16
					},
					display: true,
					text: count?"Age (year)":"나이 (세)",
					color: 'white'
				},
			},
		},
	};
	const options_age_comisa = {
		plugins: {
			legend: {
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"COMISA risk depending on the age change":"나이에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Age (year)":"나이 (세)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};

	const insomnia_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_insomnia_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_insomnia_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_insomnia_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_insomnia_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};
	const osa_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_osa_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_osa_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_osa_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_osa_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};
	const comisa_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_comisa_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_comisa_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_comisa_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_comisa_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};

    return {
		"options_weight_insomnia": options_weight_insomnia,
		"insomnia_data_weight": insomnia_data_weight,
		"options_age_insomnia": options_age_insomnia,
		"insomnia_data_age": insomnia_data_age,
		"options_weight_osa": options_weight_osa,
		"osa_data_weight": osa_data_weight,
		"options_age_osa": options_age_osa,
		"osa_data_age": osa_data_age,
		"options_weight_comisa": options_weight_comisa,
		"comisa_data_weight": comisa_data_weight,
		"options_age_comisa": options_age_comisa,
		"comisa_data_age": comisa_data_age,
		"insomnia_graph_text": insomnia_graph_text,
		"insomnia_graph_text1": insomnia_graph_text1,
		"osa_graph_text": osa_graph_text,
		"osa_graph_text1": osa_graph_text1,
		"comisa_graph_text": comisa_graph_text,
		"comisa_graph_text1": comisa_graph_text1,
	};
}