import {React, useState, useEffect} from "react"; 
import logo from "./static/logo_main_fix.png"
import './static/home.css'
import { Button } from "@material-ui/core";
import {isMobile} from 'react-device-detect';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import ButtonBase from '@material-ui/core/ButtonBase';
import award from "./static/award.png"
import home_bg from "./static/home_bg.png"
import contract from "./static/contract.png"
import ibs_smc_kaist_logo from "./static/ibs_logo_header_fix_fix.png"

import { useSelector } from "react-redux";

export default function Home(){
    //0 is english and 1 is korean
    
    const count = JSON.parse(localStorage.getItem('count'));
    
    const navigateLink = (link) =>{
        window.location.href = link
    }
    return(
        <div style={{color: 'white', background: "linear-gradient(79deg, #5A3433 0%, #1D1D1D 39.37%, #1D1D1D 48.96%, #1D1D1D 58.97%, #563B4B 100%)"}}>
        <div className="homeintro">
            {/* <div style={isMobile?{paddingLeft:"10px", paddingTop:"50px"}:{float: "left", paddingLeft:"75px", paddingTop:"70px", paddingRight: "50px", marginBottom:"100px"}}> */}
            {/* <Card sx={{ maxWidth: 500, marginBottom: "30px"}}>
                <CardActionArea href="https://www.ibs.re.kr/bimag/2023/09/bimags-ai-algorithm-for-predicting-sleep-disorder-risk-receives-prestigious-innovation-award-from-meditek/" target="blank">
                    <CardMedia
                    component="img"
                    height="250"
                    image={award}
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    {count?"SLEEPS algorithm received Innovation Award from MEDITEK!":"슬립스 알고리듬은 MEDITEK에서 이노베이션 상을 수상하였습니다."}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    We are delighted to announce that our groundbreaking AI algorithm, meticulously designed to forecast the risk of sleep disorders, has received the esteemed Innovation Award from MEDITEK.
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card> */}
            {/* <img src={logo} 
                    width={isMobile? "350px":"400px"} height={isMobile? "450px":"500px"}/> */}
            {/* </div> */}
                {/* <p style={{color: "#124b7d",paddingTop:"75px",paddingRight:isMobile?"0px":"50px", paddingLeft:isMobile?"0px":"50px", fontFamily:"Helvetica, Arial, sans-serif", fontSize:"40px", fontWeight:"bold"}}>
                    {count? "Welcome to SLEEPS" : "SLEEPS에 오신 것을 환영합니다"}
                </p> */}
                {/* <br/><br/> */}
                <table style={{paddingLeft: "20%", paddingRight: "20%", paddingTop: "100px", paddingBottom:"100px"}}>
                    <tr >
                        <td style={{paddingRight:"300px"}}>
                            <h1>더 좋은 잠을 위해 <br></br>SLEEPS</h1>
                            <p style={{opacity:'0.6', paddingTop:"10px"}}>3분만에 알아보는 수면상태 예측<br></br> 9개의 질문 답하여 수면 상태를 예측해보세요</p>
                            <Button variant="contained" style={{marginTop:"20px", backgroundColor:"#3680F7", color:"white", }} onClick={()=>navigateLink('/#/predict_sleep')}>{count?"Predict":"예측하기"}</Button>
                        </td>
                        <td><img src={contract}/></td>
                    </tr>
                </table>
                <div style={{paddingLeft: "10%", paddingRight: "10%", paddingTop:"40px", backgroundColor:"#1D1D1D", paddingBottom:"10px"}}>
                    <h2 style={{paddingLeft:isMobile?"0px":"50px"}}>Feature</h2>
                    <p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify", color:"#808080", paddingTop:"20px"}}>
                        {count? (
                        `Sleep disorders are diagnosed based on polysomnography and clinical judgment. By analyzing the polysomnography of about 5,000 people, 
                        we developed an algorithm called SLEEPS (SimpLe quEstionnairE Predicting Sleep disorders) that can predict the risk of 
                        ‘Obstructive sleep apnea (OSA)’, ‘chronic insomnia’, and ‘Comorbid insomnia and sleep apnea (COMISA)’ with simple questions 
                        and body measurement values.
                        If SLEEPS predicts the risk of ‘OSA’ as 90%, there is a 90% chance that OSA will be detected when actual polysomnography is performed.`) : 
                            (`수면 질환은 수면다원검사 및 임상적 판단을 근거로 진단합니다. 
                            본 알고리즘 SLEEPS (SimpLe quEstionnairE Predicting Sleep disorders)는 약 5,000명의 사람의 수면다원검사를 분석하여, 
                            간단한 질문과 신체측정 값만으로도 ‘수면호흡장애’, ‘만성불면증’, ‘수면호흡장애를 동반한 불면증’의 위험도를 예측할 수 있습니다.
                            SLEEPS가 ‘수면호흡장애’의 위험도를 90%로 예측했다면, 실제 수면다원검사를 시행했을 때, 수면호흡장애가 발견될 확률이 90%임을 의미합니다`)}
                    </p>
                    {count? (
                        <p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify", color:"#808080"}}>However, as this algorithm excludes expert clinical judgement, it is not intended for medical use and you should discuss your final decision with your healthcare provider.</p>) : 
                        (<p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify", color:"#808080"}}>단, 본 알고리즘은 전문가의 임상적 판단이 배제되었으므로, 의료용으로 사용할 수 없고 최종 판단은 의료진과 논의하시기 바랍니다.</p>)}
                    {count? (
                        <p style={{fontSize:"18px", padding:"15px", paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px",textAlign:"justify", color:"#808080"}}>This algorithm is developed by <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS Biomedical Mathematics Group</a> and <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST Mathematical Biology Laboratory</a> based on the data collected at Samsung Medical Center.</p>) : 
                        (<p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify", color:"#808080"}}>본 알고리즘은 삼성서울병원에서 수집된 데이터를 바탕으로 <a style={{textDecoration: "none"}}href="https://www.ibs.re.kr/bimag/">IBS 의생명수학그룹</a> 및 <a style={{textDecoration: "none" }} href="https://mathsci.kaist.ac.kr/~jaekkim/">KAIST 수리생물학 연구실</a>에서 개발했습니다.</p>)}
                    
                    <p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify", color:"#808080"}}>
                        {count? (
                        `Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023`) : 
                            (`Seokmin Ha, Su Jung Choi, Sujin Lee, Reinatt Hansel Wijaya, Jee Hyun Kim, Eun Yeon Joo, Jae Kyoung Kim, Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research, 2023`)}
                    </p>
                    <br/>
                    <center>
                    {count?"How to use SLEEPS video": "슬립스 사용법 영상"}
                    <br></br>
                    <br></br>
                    
                    {count?
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/LU-ZnC8FmeI?si=9gvfu__YIQ0SVo9n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>:
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/182VEe9Grjk?si=S2KJqzbLNwjF_MMa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>}
                    </center>
                    <p style={{fontSize:"18px", padding:"15px",paddingRight:isMobile?"15px":"50px", paddingLeft:isMobile?"0px":"50px", textAlign:"justify"}}><b>
                        {count? (
                        `NEWS`) : 
                            (`뉴스`)}
                    </b>
                    </p>
                    <ul style={{paddingLeft:"50px", paddingRight:"50px", paddingBottom:"100px", fontSize:"20px", }}>
                        <li style={{margin:"10px"}}><a style={{textDecoration: "none"}} href="https://www.ibs.re.kr/bimag/2023/09/bimags-ai-algorithm-for-predicting-sleep-disorder-risk-receives-prestigious-innovation-award-from-meditek/" target="blank">{count?"SLEEPS algorithm received Innovation Award from MEDITEK!":"슬립스 알고리듬은 MEDITEK에서 이노베이션 상을 수상하였습니다."}</a></li>
                        <li style={{margin:"10px"}}><a style={{textDecoration: "none"}} href="https://n.news.naver.com/article/056/0011625091?sid=105" target="blank">{count?"KBS Dajeon News":"슬립스가 KBS 대전 뉴스에 소개되었습니다."}</a></li>
                    </ul>
                    <center style={{marginBottom:"100px"}}>
                        <img src={ibs_smc_kaist_logo} width="50%"/>
                    </center>
            </div>
            
        </div>
        </div>
    )
}