import React, { useState } from "react";

export default function PredictHeader(){

    return (
		<>
			<h1>예측하기</h1>
            <p style={{opacity: "0.6", paddingTop: "20px"}}>간단한 정보들을 가지고 수면 상태를 예측합니다.</p>
		</>
	);
}