import React, { useState } from "react";
import "./static/predict.css";
import FormUserInfo from "./components/FormUserInfo";
import FormUserAnswer from "./components/FormUserAnswer";
import PredictHeader from "./components/PredictHeader";
// import Select from 'react-select'

export default function Predict() {
	const [formData, setFormData] = useState({
		sex: 0,
		age: 0,
		nationality: "",
		height: 0,
		weight: 0,
		ISI2: 0,
		ISI5: 0,
		ISI1a: 0,
		ISI1b: 0,
		ISI1c: 0,
	});

	//steps
	const [step, setStep] = useState(0);

	const PageDisplay = () => {
		if (step === 0) {
			return (
				<div style={{marginTop:"40px"}}>
					<FormUserInfo
						formData={formData}
						setFormData={setFormData}
						step={step}
						setStep={setStep}
					/>
				</div>
			);
		} else if (step === 1) {
			return (
				<div style={{marginTop:"40px"}}>
					<FormUserAnswer
						formData={formData}
						setFormData={setFormData}
						step={step}
						setStep={setStep}
						age={formData.age - 5}
						weight={formData.weight - 5}
					/>
				</div>
			);
		}
	};

	return (
		<>
			<div style={{backgroundColor: "black", color:"white", paddingTop: "50px", paddingLeft: "20%", paddingRight: "20%"}}>
				<div style={{}}>
					<PredictHeader/>
				</div>
				<center>
					{PageDisplay()}
				</center>
			</div>
		</>
	);
}

//select option
/* <Select value={sex} onChange={(e) => {setSex(e)}}
options={[{value: 'male', label:'Male'}, {value:'female', label:'Female'}]} /><br/> */
