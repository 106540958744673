import {
	InputAdornment,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
} from "@material-ui/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import LoadingSpinner from "./LoadingSpinner";
import { useSelector } from "react-redux";
import SelectInput from "@material-ui/core/Select/SelectInput";
import {isMobile} from 'react-device-detect';
import CSRFToken from './csrf_token';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from "@mui/material/styles";
import { pink } from '@mui/material/colors';

export const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
	color:"#fff",
	opacity:"0.3",
	"&.Mui-selected": {
	 color: "#fff",
	 opacity: '1.0',
	 backgroundColor: "#323339",
	//   "&:hover": {
	//    color: "#fff",
	//    opacity: '1.0',
	//    backgroundColor: "#323339",
	//   },
	},
	"&:hover": {
	 color: "#fff",
	 opacity: '1.0',
	 backgroundColor: "#323339",
   },
  }));

export default function FormUserAnswer({
	formData,
	setFormData,
	step,
	setStep,
	age,
	weight,
}) {
	const count = JSON.parse(localStorage.getItem('count'));
	let insomniaResult = [];
	let OSAResult = [];
	let COMISAResult = [];

	//add new variable for age variation graph
	let insomniaResultAge = [];
	let OSAResultAge = [];
	let COMISAResultAge = [];

	//opacity
	const [opacity1, SetOpacity1] = useState(1);
	const [opacity2, SetOpacity2] = useState(1);
	const [opacity3, SetOpacity3] = useState(1);
	const [opacity4, SetOpacity4] = useState(1);
	const [opacity5, SetOpacity5] = useState(1);

	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const ref5 = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const delay = ms => new Promise(
		resolve => setTimeout(resolve, ms)
	  );

	let navigate = useNavigate(); 
  	const routeChange = (res) =>{ 
    	let path = '/result'; 
    	navigate(path, {state: {res}});
  	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		let BMI =
			Math.round(
				(formData.weight / (formData.height * formData.height)) * 1000000
			) / 100;
		let form_data = {
			name: formData.name,
			sex: formData.sex,
			age: formData.age,
			weight: formData.weight,
			height: formData.height,
			BMI: BMI,
			nationality: formData.nationality,
			ISI2: formData.ISI2,
			ISI5: formData.ISI5,
			ISI1a: formData.ISI1a,
			ISI1b: formData.ISI1b,
			ISI1c: formData.ISI1c,
		};

		let axiosreq = [];

		let baseUrl = "https://www.sleep-math.com/predict/";
		axiosreq.push(
			axios.post(baseUrl + "insomnia/age", form_data).then((res) => {
				console.log("Insomnia Age: ", res.data);
				for (let i = 0; i < 11; i++) {
					// console.log(res.data[i]["insomnia_probability"][1]);

					insomniaResultAge.push(res.data[i])
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "osa/age", form_data).then((res) => {
				console.log("OSA Age: ", res.data);
				for (let i = 0; i < 11; i++) {
					OSAResultAge.push(res.data[i]);
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "comisa/age", form_data).then((res) => {
				console.log("COMISA Age: ", res.data);
				for (let i = 0; i < 11; i++) {
					COMISAResultAge.push(res.data[i]);
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "insomnia/", form_data).then((res) => {
				console.log("Insomnia Weight: ", res.data);
				for (let i = 0; i < 11; i++) {
					// console.log(res.data[i]["insomnia_probability"][1]);

					insomniaResult.push(res.data[i]);
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "osa/", form_data).then((res) => {
				console.log("OSA Weight:", res.data);
				for (let i = 0; i < 11; i++) {
					OSAResult.push(res.data[i]);
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "comisa/", form_data).then((res) => {
				console.log("COMISA weight: ", res.data);
				for (let i = 0; i < 11; i++) {
					COMISAResult.push(res.data[i]);
				}
			})
		);
		axiosreq.push(
			axios.post(baseUrl + "upload/", form_data).then((res) => {
				console.log("Upload: ", res);
			})
		);
		setIsLoading(true)
		console.log("pausing...")
		await delay(12000);
		console.log("finished pausing")
		setTimeout(function (){
			
			axios
			.all([
				axiosreq[0],
				axiosreq[1],
				axiosreq[2],
				axiosreq[3],
				axiosreq[4],
				axiosreq[5],
				axiosreq[6],
			])
			.then((res) => {
				const data = {
					"sex": formData.sex,
					"age": age,
					"weight": weight,
					"insomniaResult": insomniaResult,
					"OSAResult": OSAResult,
					"COMISAResult": COMISAResult,
					"insomniaResultAge": insomniaResultAge,
					"OSAResultAge": OSAResultAge,
					"COMISAResultAge": COMISAResultAge,
				}
				setIsLoading(false);
				routeChange(data);
			})
			.catch(()=>{
				setErrorMessage("Unable to fetch result" + errorMessage)
				setIsLoading(false)
			});
		}, 5000)
	};

	return (
		<>
			{errorMessage}
			<form onSubmit={(e) => handleSubmit(e)}>
				<CSRFToken />
				{isLoading?<LoadingSpinner/>:<>

				{isMobile?
				<>
				<table>
					<tr>
				<label className="font-control" style={{opacity:opacity1, fontSize:"20px"}}>
					{count?"Have you had trouble falling asleep in the past two weeks?":"지난 2주간 잠드는데 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
					<FormControl required>
						<RadioGroup
							ref={ref1}
							name="ISI1a"
							value={formData.ISI1a}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity1}}
							onChange={(e) => {
								setFormData({ ...formData, ISI1a: parseInt(e.target.value) });
								// SetOpacity1(0.3)
								// SetOpacity2(1)
								// ref2.current.scrollIntoView({behavior: 'auto',
								// block: 'center',
								// inline: 'center'}
								// )
							}}
						>
							<FormControlLabel
								value={0}
								sx={{
									color: pink[800],
									'&.Mui-checked': {
									  color: pink[800],
									},
								  }} 
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl>
					</tr>
					<tr>
				<label className="font-control" style={{opacity:opacity2, fontSize:"20px"}}>
					{count?"Have you had trouble staying asleep over the past two weeks?":"지난 2주간 잠을 유지하는데 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
					<FormControl>
						<RadioGroup
							ref={ref2}
							name="ISI1b"
							value={formData.ISI1b}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity2}}
							onChange={(e) => {
								setFormData({ ...formData, ISI1b: parseInt(e.target.value) });
								// SetOpacity2(0.3)
								// SetOpacity3(1)
								// ref3.current.scrollIntoView({behavior: 'auto',
								// block: 'center',
								// inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl>
					</tr>
					<tr>
				<label className="font-control" style={{opacity:opacity3, fontSize:"20px"}}>
					{count?"Have you had trouble for waking up too early in the past two weeks?":"지난 2주간 너무 일찍 깨서 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
					<FormControl required>
						<RadioGroup
							ref={ref3}
							name="ISI1c"
							value={formData.ISI1c}
							style={{ marginTop: "20px", marginBottom: "30px", opacity:opacity3 }}
							onChange={(e) => {
								setFormData({ ...formData, ISI1c: parseInt(e.target.value) });
								// SetOpacity3(0.3)
								// SetOpacity4(1)
								// ref4.current.scrollIntoView({behavior: 'auto',
								// block: 'center',
								// inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} style={{ color: 'white' }} />}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl>
					</tr>
					<tr>
				<label className="font-control" style={{opacity:opacity4, fontSize:"20px"}}>
					{count?<>How <b>satisfied or dissatisfied</b> are you with your <b>current</b> sleep pattern?</>:"요즘 당신의 수면패턴에 대해 얼마나 만족하십니까?"}
				</label>
				</tr>
				<tr>
					<FormControl required>
						<RadioGroup
							ref={ref4}
							name="ISI2"
							value={formData.ISI2}
							style={{ marginTop: "20px", marginBottom: "30px", opacity:opacity4 }}
							onChange={(e) => {
								setFormData({ ...formData, ISI2: parseInt(e.target.value) });
								// SetOpacity4(0.3)
								// SetOpacity5(1)
								// ref5.current.scrollIntoView({behavior: 'auto',
								// block: 'center',
								// inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very Satisfied":"매우 만족"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Satisfied":"만족"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Moderately Satisfied":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Dissatisfied":"불만족"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very Dissatisfied":"매우 불만족"}
							/>
						</RadioGroup>
					</FormControl>
					</tr>
					<tr>
					<label className="font-control" style={{opacity:opacity5, fontSize:"20px"}}>
						{count?<>To what extent do you consider your sleep problem to{" "}
						<b>
							interfere
						</b>{" "}
						with your daily functioning (eg, daytime fatigue, mood, ability to
						function at work or daily chores, concentration, memory, mood, etc){" "}
						<b>
							currently
						</b>
						?</>:"지금 당신의 수면 문제가 일상 기능(예. 주간 활동, 주간 피로감, 집중력, 기억력 등)을 어느정도 방해한다고 생각합니까?"}
					</label>
					</tr>
					<tr>
					<FormControl required>
						<RadioGroup
							ref={ref5}
							name="ISI5"
							value={formData.ISI5}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity5}}
							onChange={(e) => {
								setFormData({ ...formData, ISI5: parseInt(e.target.value) });
								// SetOpacity5(0.3)
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Not at all interfering":"전혀"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"A little":"조금"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Somewhat":"다소"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Much":"많이"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} style={{ color: 'white' }}/>}
								label={count?"Very much interfering":"매우 많이"}
							/>
						</RadioGroup>
					</FormControl>
					</tr>
					</table>
				</>
				:
				<>
				<table>
					<tr>
				<label className="font-control" >
					{count?"Have you had trouble falling asleep in the past two weeks?":"지난 2주간 잠드는데 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
				<ToggleButtonGroup
					fullWidth
					required
					value={formData.ISI1a}
					exclusive
					onChange={(e) => {
						if(e.target.value != null){
							setFormData({ ...formData, ISI1a: parseInt(e.target.value) });
						}
					}}
					aria-label="text alignment"
					sx={{color:'white', backgroundColor: '#37383E', width: '800px', marginTop: "20px", marginBottom: "20px"}}
				>
					<CustomToggleButton value={0} aria-label="left aligned" color="primary">
					{count?"None":"없음"}
					</CustomToggleButton>
					<CustomToggleButton value={1} aria-label="right aligned" color="primary">
					{count?"Mild":"약간"}
					</CustomToggleButton>
					<CustomToggleButton value={2} aria-label="left aligned" color="primary">
					{count?"Moderate":"중간"}
					</CustomToggleButton>
					<CustomToggleButton value={3} aria-label="left aligned" color="primary">
					{count?"Severe":"심함"}
					</CustomToggleButton>
					<CustomToggleButton value={4} aria-label="left aligned" color="primary">
					{count?"Very Severe":"매우 심함"}
					</CustomToggleButton>
				</ToggleButtonGroup>
					{/* <FormControl required marginNormal>
						<RadioGroup
							row
							ref={ref1}
							name="ISI1a"
							value={formData.ISI1a}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity1}}
							onChange={(e) => {
								setFormData({ ...formData, ISI1a: parseInt(e.target.value) });
								SetOpacity1(0.3)
								SetOpacity2(1)
								ref2.current.scrollIntoView({behavior: 'auto',
								block: 'center',
								inline: 'center'}
								)
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} />}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} />}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} />}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} />}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} />}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl> */}
					</tr>
					<tr>
				<label className="font-control" >
					{count?"Have you had trouble staying asleep over the past two weeks?":"지난 2주간 잠을 유지하는데 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
				<ToggleButtonGroup
					fullWidth
					required
					value={formData.ISI1b}
					exclusive
					onChange={(e) => {
						if(e.target.value != null){
							setFormData({ ...formData, ISI1b: parseInt(e.target.value) });
						}
					}}
					aria-label="text alignment"
					sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '800px', marginTop: "20px", marginBottom: "20px"}}
				>
					<CustomToggleButton value={0} aria-label="left aligned" color="primary">
					{count?"None":"없음"}
					</CustomToggleButton>
					<CustomToggleButton value={1} aria-label="right aligned" color="primary">
					{count?"Mild":"약간"}
					</CustomToggleButton>
					<CustomToggleButton value={2} aria-label="left aligned" color="primary">
					{count?"Moderate":"중간"}
					</CustomToggleButton>
					<CustomToggleButton value={3} aria-label="left aligned" color="primary">
					{count?"Severe":"심함"}
					</CustomToggleButton>
					<CustomToggleButton value={4} aria-label="left aligned" color="primary">
					{count?"Very Severe":"매우 심함"}
					</CustomToggleButton>
				</ToggleButtonGroup>
					{/* <FormControl>
						<RadioGroup
							row
							ref={ref2}
							name="ISI1b"
							value={formData.ISI1b}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity2}}
							onChange={(e) => {
								setFormData({ ...formData, ISI1b: parseInt(e.target.value) });
								SetOpacity2(0.3)
								SetOpacity3(1)
								ref3.current.scrollIntoView({behavior: 'auto',
								block: 'center',
								inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} />}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} />}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} />}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} />}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} />}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl> */}
					</tr>
					<tr>
				<label className="font-control" >
					{count?"Have you had trouble for waking up too early in the past two weeks?":"지난 2주간 너무 일찍 깨서 어려움이 있었습니까?"}
				</label>
				</tr>
				<tr>
				<ToggleButtonGroup
					fullWidth
					required
					value={formData.ISI1c}
					exclusive
					onChange={(e) => {
						if(e.target.value != null){
							setFormData({ ...formData, ISI1c: parseInt(e.target.value) });
						}
					}}
					aria-label="text alignment"
					sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '800px', marginTop: "20px", marginBottom: "20px"}}
				>
					<CustomToggleButton value={0} aria-label="left aligned" color="primary">
					{count?"None":"없음"}
					</CustomToggleButton>
					<CustomToggleButton value={1} aria-label="right aligned" color="primary">
					{count?"Mild":"약간"}
					</CustomToggleButton>
					<CustomToggleButton value={2} aria-label="left aligned" color="primary">
					{count?"Moderate":"중간"}
					</CustomToggleButton>
					<CustomToggleButton value={3} aria-label="left aligned" color="primary">
					{count?"Severe":"심함"}
					</CustomToggleButton>
					<CustomToggleButton value={4} aria-label="left aligned" color="primary">
					{count?"Very Severe":"매우 심함"}
					</CustomToggleButton>
				</ToggleButtonGroup>
					{/* <FormControl required>
						<RadioGroup
							row
							ref={ref3}
							name="ISI1c"
							value={formData.ISI1c}
							style={{ marginTop: "20px", marginBottom: "30px", opacity:opacity3 }}
							onChange={(e) => {
								setFormData({ ...formData, ISI1c: parseInt(e.target.value) });
								SetOpacity3(0.3)
								SetOpacity4(1)
								ref4.current.scrollIntoView({behavior: 'auto',
								block: 'center',
								inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} />}
								label={count?"None":"없음"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} />}
								label={count?"Mild":"약간"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} />}
								label={count?"Moderate":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} />}
								label={count?"Severe":"심함"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} />}
								label={count?"Very Severe":"매우 심함"}
							/>
						</RadioGroup>
					</FormControl> */}
					</tr>
					<tr>
				<label className="font-control" >
					{count?<>How <b>satisfied or dissatisfied</b> are you with your <b>current</b> sleep pattern?</>:"요즘 당신의 수면패턴에 대해 얼마나 만족하십니까?"}
				</label>
				</tr>
				<tr>
				<ToggleButtonGroup
					fullWidth
					value={formData.ISI2}
					exclusive
					required
					onChange={(e) => {
						if(e.target.value != null){
							setFormData({ ...formData, ISI2: parseInt(e.target.value) });
						}
					}}
					aria-label="text alignment"
					sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '800px', marginTop: "20px", marginBottom: "20px"}}
				>
					<CustomToggleButton value={0} aria-label="left aligned" color="primary">
					{count?"Very Satisfied":"매우 만족"}
					</CustomToggleButton>
					<CustomToggleButton value={1} aria-label="right aligned" color="primary">
					{count?"Satisfied":"만족"}
					</CustomToggleButton>
					<CustomToggleButton value={2} aria-label="left aligned" color="primary">
					{count?"Moderately Satisfied":"중간"}
					</CustomToggleButton>
					<CustomToggleButton value={3} aria-label="left aligned" color="primary">
					{count?"Dissatisfied":"불만족"}
					</CustomToggleButton>
					<CustomToggleButton value={4} aria-label="left aligned" color="primary">
					{count?"Very Dissatisfied":"매우 불만족"}
					</CustomToggleButton>
				</ToggleButtonGroup>
					{/* <FormControl required>
						<RadioGroup
							row
							ref={ref4}
							name="ISI2"
							value={formData.ISI2}
							style={{ marginTop: "20px", marginBottom: "30px", opacity:opacity4 }}
							onChange={(e) => {
								setFormData({ ...formData, ISI2: parseInt(e.target.value) });
								SetOpacity4(0.3)
								SetOpacity5(1)
								ref5.current.scrollIntoView({behavior: 'auto',
								block: 'center',
								inline: 'center'})
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} />}
								label={count?"Very Satisfied":"매우 만족"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} />}
								label={count?"Satisfied":"만족"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} />}
								label={count?"Moderately Satisfied":"중간"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} />}
								label={count?"Dissatisfied":"불만족"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} />}
								label={count?"Very Dissatisfied":"매우 불만족"}
							/>
						</RadioGroup>
					</FormControl> */}
					</tr>
					<tr>
					<label className="font-control" >
						{count?<>To what extent do you consider your sleep problem to{" "}
						<b>
							interfere
						</b>{" "}
						with your daily functioning (eg, daytime fatigue, mood, ability to
						function at work or daily chores, concentration, memory, mood, etc){" "}
						<b>
							currently
						</b>
						?</>:"지금 당신의 수면 문제가 일상 기능(예. 주간 활동, 주간 피로감, 집중력, 기억력 등)을 어느정도 방해한다고 생각합니까?"}
					</label>
				</tr>
				<tr>
				<ToggleButtonGroup
					fullWidth
					value={formData.ISI5}
					exclusive
					required
					onChange={(e) => {
						if(e.target.value != null){
							setFormData({ ...formData, ISI5: parseInt(e.target.value) });
						}
					}}
					aria-label="text alignment"
					sx={{color:'white', borderColor:'white', backgroundColor: '#37383E', width: '800px', marginTop: "20px", marginBottom: "20px"}}
				>
					<CustomToggleButton value={0} aria-label="left aligned" color="primary">
					{count?"Not at all interfering":"전혀"}
					</CustomToggleButton>
					<CustomToggleButton value={1} aria-label="right aligned" color="primary">
					{count?"A little":"조금"}
					</CustomToggleButton>
					<CustomToggleButton value={2} aria-label="left aligned" color="primary">
					{count?"Somewhat":"다소"}
					</CustomToggleButton>
					<CustomToggleButton value={3} aria-label="left aligned" color="primary">
					{count?"Much":"많이"}
					</CustomToggleButton>
					<CustomToggleButton value={4} aria-label="left aligned" color="primary">
					{count?"Very much interfering":"매우 많이"}
					</CustomToggleButton>
				</ToggleButtonGroup>
					{/* <FormControl required>
						<RadioGroup
							row
							ref={ref5}
							name="ISI5"
							value={formData.ISI5}
							style={{ marginTop: "20px", marginBottom: "30px" , opacity:opacity5}}
							onChange={(e) => {
								setFormData({ ...formData, ISI5: parseInt(e.target.value) });
								SetOpacity5(0.3)
							}}
						>
							<FormControlLabel
								value={0}
								control={<Radio required={true} />}
								label={count?"Not at all interfering":"전혀"}
							/>
							<FormControlLabel
								value={1}
								control={<Radio required={true} />}
								label={count?"A little":"조금"}
							/>
							<FormControlLabel
								value={2}
								control={<Radio required={true} />}
								label={count?"Somewhat":"다소"}
							/>
							<FormControlLabel
								value={3}
								control={<Radio required={true} />}
								label={count?"Much":"많이"}
							/>
							<FormControlLabel
								value={4}
								control={<Radio required={true} />}
								label={count?"Very much interfering":"매우 많이"}
							/>
						</RadioGroup>
					</FormControl> */}
					</tr>
					</table>
				</>
				}

				{isMobile?
				
				<>
				<table>
					<tr>
						<td>
				<Button
					type="button"
					variant="outlined"
					style={{ backgroundColor: '#3680F7', width: "120px", fontSize: "20px", marginBottom: "100px", color:"white", borderColor:"white", marginTop: "40px" }}
					onClick={() => {
						setStep(step - 1);
					}}
				>
					{count?"Prev":"이전"}
				</Button>
				</td>
				<td>
				<Button
					style={{backgroundColor: '#3680F7', width: "120px", fontSize: "20px", marginBottom: "100px", color:"white", borderColor:"white", marginTop: "40px", marginLeft: '20%' }}
					type="submit"
					variant="outlined"
				>
					{count?"Submit":"제출하기"}
				</Button>
				</td>
				</tr>
				</table>
				</>:
				
				<>
				<Button
					type="button"
					variant="outlined"
					style={{ backgroundColor: '#3680F7', width: "150px", fontSize: "20px", marginBottom: "100px", color:"white", borderColor:"white", marginTop: "40px" }}
					onClick={() => {
						setStep(step - 1);
					}}
				>
					{count?"Prev":"이전"}
				</Button>
				<Button
					style={{backgroundColor: '#3680F7', width: "150px", fontSize: "20px", marginBottom: "100px", color:"white", borderColor:"white", marginTop: "40px", marginLeft: '50%' }}
					type="submit"
					variant="outlined"
				>
					{count?"Submit":"제출하기"}
				</Button>
				</>}
				
				
				</>
				
				}
			</form>
		</>
	);
}
