import React from "react";
import { useLocation } from "react-router-dom";
import "../static/detail.css"
import { Line } from "react-chartjs-2";
import { useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";
import {isMobile} from 'react-device-detect';
import {insomnia0_mean, insomnia0_std, 
	insomnia1_mean, insomnia1_std,
	osa0_mean, osa0_std,
	osa1_mean, osa1_std,
	comisa0_mean, comisa0_std,
	comisa1_mean, comisa1_std} from "./components/Data";
// import "./static/predict.css";

export default function Detail() {
	const count = JSON.parse(localStorage.getItem('count'));
  	const { state } = useLocation();
	let navigate = useNavigate();
  	const id = state.res["id"];

	let sex = state.res["sex"];
	let age = state.res["age"];
	let weight = state.res["weight"];
	let insomniaResult = state.res["insomniaResult"];
	let OSAResult = state.res["OSAResult"];
	let COMISAResult = state.res["COMISAResult"];
	let insomniaResultAge = state.res["insomniaResultAge"];
	let OSAResultAge = state.res["OSAResultAge"];
	let COMISAResultAge = state.res["COMISAResultAge"];

	const data_insomnia = [];
	const data_osa = [];
	const data_comisa = [];

	const data_insomnia_age = [];
	const data_osa_age = [];
	const data_comisa_age = [];

	const data_insomnia_age_mean = [];
	const data_osa_age_mean = [];
	const data_comisa_age_mean = [];

	const data_insomnia_std1 = [];
	const data_osa_std1 = [];
	const data_comisa_std1 = [];

	const data_insomnia_age_std1 = [];
	const data_osa_age_std1 = [];
	const data_comisa_age_std1 = [];

	const data_insomnia_age_mean_std1 = [];
	const data_osa_age_mean_std1 = [];
	const data_comisa_age_mean_std1 = [];

	const data_insomnia_std2 = [];
	const data_osa_std2 = [];
	const data_comisa_std2 = [];

	const data_insomnia_age_std2 = [];
	const data_osa_age_std2 = [];
	const data_comisa_age_std2 = [];

	const data_insomnia_age_mean_std2 = [];
	const data_osa_age_mean_std2 = [];
	const data_comisa_age_mean_std2 = [];

	const weight_label = [];
	const age_label = [];

	let new_weight_diff_insomnia = -1000;
	let new_weight_diff_osa = -1000;
	let new_weight_diff_comisa = -1000;
	let new_weight_diff_insomnia_percent = -1;
	let new_weight_diff_osa_percent = -1;
	let new_weight_diff_comisa_percent = -1;

	//   console.log("DATA:", insomniaResult);
	//   console.log("DATA:", OSAResult);
	//   console.log("DATA:", COMISAResult);
	for (let i = 0; i < 11; i++) {
		data_insomnia.push(insomniaResult[i]["insomnia_probability"] * 100);
		data_osa.push(OSAResult[i]["osa_probability"] * 100);
		data_comisa.push(COMISAResult[i]["comisa_probability"] * 100);

		data_insomnia_age.push(insomniaResultAge[i]["insomnia_probability"] * 100);
		data_osa_age.push(OSAResultAge[i]["osa_probability"] * 100);
		data_comisa_age.push(COMISAResultAge[i]["comisa_probability"] * 100);
		
		if(sex == 0){
			let idx0 = Math.min(age, 86)
			let insomnia_mean_temp = insomnia0_mean[idx0]
			let osa_mean_temp = osa0_mean[idx0]
			let comisa_mean_temp = comisa0_mean[idx0]

			let insomnia_std1_temp = insomnia_mean_temp + insomnia0_std[idx0]
			let insomnia_std2_temp = insomnia_mean_temp - insomnia0_std[idx0]
			let osa_std1_temp = osa_mean_temp + osa0_std[idx0]
			let osa_std2_temp = osa_mean_temp - osa0_std[idx0]
			let comisa_std1_temp = comisa_mean_temp + comisa0_std[idx0]
			let comisa_std2_temp = comisa_mean_temp - comisa0_std[idx0]

			data_insomnia_age_mean.push(insomnia_mean_temp * 100);
			data_osa_age_mean.push(osa_mean_temp * 100);
			data_comisa_age_mean.push(comisa_mean_temp * 100);

			data_insomnia_age_mean_std1.push(insomnia_std1_temp * 100);
			data_insomnia_age_mean_std2.push(insomnia_std2_temp * 100);
			data_osa_age_mean_std1.push(osa_std1_temp * 100);
			data_osa_age_mean_std2.push(osa_std2_temp * 100);
			data_comisa_age_mean_std1.push(comisa_std1_temp * 100);
			data_comisa_age_mean_std2.push(comisa_std2_temp * 100);
		}else{
			let idx1 = Math.min(age, 86)
			let insomnia_mean_temp = insomnia1_mean[idx1]
			let osa_mean_temp = osa1_mean[idx1]
			let comisa_mean_temp = comisa1_mean[idx1]

			let insomnia_std1_temp = insomnia_mean_temp + insomnia1_std[idx1]
			let insomnia_std2_temp = insomnia_mean_temp - insomnia1_std[idx1]
			let osa_std1_temp = osa_mean_temp + osa1_std[idx1]
			let osa_std2_temp = osa_mean_temp - osa1_std[idx1]
			let comisa_std1_temp = comisa_mean_temp + comisa1_std[idx1]
			let comisa_std2_temp = comisa_mean_temp - comisa1_std[idx1]

			data_insomnia_age_mean.push(insomnia_mean_temp * 100);
			data_osa_age_mean.push(osa_mean_temp * 100);
			data_comisa_age_mean.push(comisa_mean_temp * 100);

			data_insomnia_age_mean_std1.push(insomnia_std1_temp * 100);
			data_insomnia_age_mean_std2.push(insomnia_std2_temp * 100);
			data_osa_age_mean_std1.push(osa_std1_temp * 100);
			data_osa_age_mean_std2.push(osa_std2_temp * 100);
			data_comisa_age_mean_std1.push(comisa_std1_temp * 100);
			data_comisa_age_mean_std2.push(comisa_std2_temp * 100);
		}

		if(new_weight_diff_insomnia == -1000 && Math.abs(data_insomnia[i] - (insomniaResult[5]["insomnia_probability"] * 100)) >= 5){
			new_weight_diff_insomnia = i-5
			new_weight_diff_insomnia_percent = data_insomnia[i] - (insomniaResult[5]["insomnia_probability"] * 100)
		}
		if(new_weight_diff_osa == -1000 && Math.abs(data_osa[i] - (OSAResult[5]["osa_probability"] * 100)) >= 5){
			new_weight_diff_osa = i-5
			new_weight_diff_osa_percent = data_osa[i] - (OSAResult[5]["osa_probability"] * 100)
		}
		if(new_weight_diff_comisa == -1000 && Math.abs(data_comisa[i] - (COMISAResult[5]["comisa_probability"] * 100)) >= 5){
			new_weight_diff_comisa = i-5
			new_weight_diff_comisa_percent = data_comisa[i] - (COMISAResult[5]["comisa_probability"] * 100)
		}

		weight_label.push(weight);
		age_label.push(age);

		age = age + 1;
		weight = weight + 1;

		//add the std
		data_insomnia_std1.push(
			data_insomnia[i] - insomniaResult[i]["insomnia_std"] * 100
		);
		data_insomnia_std2.push(
			data_insomnia[i] + insomniaResult[i]["insomnia_std"] * 100
		);

		data_insomnia_age_std1.push(
			data_insomnia_age[i] - insomniaResult[i]["insomnia_std"] * 100
		);
		data_insomnia_age_std2.push(
			data_insomnia_age[i] + insomniaResult[i]["insomnia_std"] * 100
		);

		data_osa_std1.push(data_osa[i] - OSAResult[i]["osa_std"] * 100);
		data_osa_std2.push(data_osa[i] + OSAResult[i]["osa_std"] * 100);

		data_osa_age_std1.push(data_osa_age[i] - OSAResult[i]["osa_std"] * 100);
		data_osa_age_std2.push(data_osa_age[i] + OSAResult[i]["osa_std"] * 100);

		data_comisa_std1.push(data_comisa[i] - COMISAResult[i]["comisa_std"] * 100);
		data_comisa_std2.push(data_comisa[i] + COMISAResult[i]["comisa_std"] * 100);

		data_comisa_age_std1.push(
			data_comisa_age[i] - COMISAResult[i]["comisa_std"] * 100
		);
		data_comisa_age_std2.push(
			data_comisa_age[i] + COMISAResult[i]["comisa_std"] * 100
		);
	}

	// const data = [
	// 	{
	// 		data: {
	// 			Insomnia: insomniaResult[5]["insomnia_probability"],
	// 			OSA: OSAResult[5]["osa_probability"],
	// 			COMISA: COMISAResult[5]["comisa_probability"],
	// 		},
	// 		meta: { color: "red" },
	// 	},
	// ];
	// const captions = {
	// 	Insomnia: "Insomnia",
	// 	OSA: "OSA",
	// 	COMISA: "COMISA",
	// };
	let insomnia_graph_text1 = ""
	let osa_graph_text1 = ""
	let comisa_graph_text1 = ""
	if(new_weight_diff_insomnia > 0){
		if(new_weight_diff_insomnia_percent > 0){
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would increase by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = "체중이 " + new_weight_diff_insomnia + "kg 증가하면 불면증 위험이 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_insomnia_percent = Math.abs(new_weight_diff_insomnia_percent)
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would decrease by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = "체중이 " + new_weight_diff_insomnia + "kg 증가하면 불면증 위험이 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_insomnia = Math.abs(new_weight_diff_insomnia)
		if(new_weight_diff_insomnia_percent > 0){
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would increase by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = new_weight_diff_insomnia + "kg 감량하면 불면증 위험 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_insomnia_percent = Math.abs(new_weight_diff_insomnia_percent)
			if(count == 1){
				insomnia_graph_text1 = "Your risk of insomnia would decrease by " + new_weight_diff_insomnia_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_insomnia + " kg"
			}else{
				insomnia_graph_text1 = new_weight_diff_insomnia + "kg 감량하면 불면증 위험 " + new_weight_diff_insomnia_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_osa > 0){
		if(new_weight_diff_osa_percent > 0){
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would increase by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = "체중이 " + new_weight_diff_osa + "kg 증가하면 수면호흡장애 위험이 " + new_weight_diff_osa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_osa_percent = Math.abs(new_weight_diff_osa_percent)
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would decrease by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = "체중이 " + new_weight_diff_osa + "kg 증가하면 수면호흡장애 위험이 " + new_weight_diff_osa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_osa = Math.abs(new_weight_diff_osa)
		if(new_weight_diff_osa_percent > 0){
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would increase by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = new_weight_diff_osa + "kg 감량하면 수면호흡장애 위험 "+ new_weight_diff_osa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_osa_percent = Math.abs(new_weight_diff_osa_percent)
			if(count == 1){
				osa_graph_text1 = "Your risk of OSA would decrease by " + new_weight_diff_osa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_osa + " kg"
			}else{
				osa_graph_text1 = new_weight_diff_osa + "kg 감량하면 수면호흡장애 위험 " + new_weight_diff_osa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_comisa > 0){
		if(new_weight_diff_comisa_percent > 0){
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would increase by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = "체중이 " + new_weight_diff_comisa + "kg 증가하면 수면호흡장애를 동반한 불면증 위험이 " + new_weight_diff_comisa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_comisa_percent = Math.abs(new_weight_diff_comisa_percent)
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would decrease by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight increased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = "체중이 " + new_weight_diff_comisa + "kg 증가하면 수면호흡장애를 동반한 불면증 위험이 " + new_weight_diff_comisa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}else{
		new_weight_diff_comisa = Math.abs(new_weight_diff_comisa)
		if(new_weight_diff_comisa_percent > 0){
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would increase by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = new_weight_diff_comisa + "kg 감량하면 수면호흡장애를 동반한 불면증 위험 "+ new_weight_diff_comisa_percent.toPrecision(2) + "% 증가합니다"
			}
		}else{
			new_weight_diff_comisa_percent = Math.abs(new_weight_diff_comisa_percent)
			if(count == 1){
				comisa_graph_text1 = "Your risk of COMISA would decrease by " + new_weight_diff_comisa_percent.toPrecision(2) + "% if your weight decreased by " + new_weight_diff_comisa + " kg"
			}else{
				comisa_graph_text1 = new_weight_diff_comisa + "kg 감량하면 수면호흡장애를 동반한 불면증 위험 "+ new_weight_diff_comisa_percent.toPrecision(2) + "% 감소합니다"
			}
		}
	}
	if(new_weight_diff_insomnia == 1000){
		insomnia_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}
	if(new_weight_diff_osa == 1000){
		osa_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}
	if(new_weight_diff_comisa == 1000){
		comisa_graph_text1 = count?"your weight range is quite stable":"몸무게 변화에 따라 위험도 변화가 없습니다"
	}

	const insomnia_graph_text = data_insomnia_age[5]-data_insomnia_age_mean[5]>0?(count?"Your insomnia risk is higher than the average person on your age":"당신의 불면증 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
									(count?"Your insomnia risk is lower than the average person on your age": "당신의 불면증 위험은 당신 나이대의 평균적인 사람보다 낮습니다");
	const osa_graph_text = data_osa_age[5]-data_osa_age_mean[5]>0?(count?"Your OSA risk is higher than the average person on your age":"당신의 수면호흡장애 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
	(count?"Your OSA risk is lower than the average person on your age": "당신의 수면호흡장애 위험은 당신 나이대의 평균적인 사람보다 낮습니다");
	const comisa_graph_text = data_comisa_age[5]-data_comisa_age_mean[5]>0?(count?"Your COMISA risk is higher than the average person on your age":"당신의 수면호흡장애를 동반한 불면증 위험은 당신의 나이에 평균적인 사람보다 높습니다"):
	(count?"Your COMISA risk is lower than the average person on your age": "당신의 수면호흡장애를 동반한 불면증 위험은 당신 나이대의 평균적인 사람보다 낮습니다");

	const options_weight_insomnia = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"Insomnia risk depending on the weight change":"몸무게에 따른 만성불면증 위험도 변화",
				font: {
					size: isMobile?18:24,
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_weight_osa = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"OSA risk depending on the weight change":"몸무게에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_weight_comisa = {
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: count?"COMISA risk depending on the weight change":"몸무게에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					font:{
						size:isMobile?10:16
					},
					display: true,
					text: count?"Weight (kg)":"몸무게 (kg)",
					color: 'white'
				},
			},
		},
	};

	const insomnia_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_insomnia,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_insomnia_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_insomnia_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},

		],
	};
	const osa_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_osa,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_osa_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_osa_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
		],
	};
	const comisa_data_weight = {
		labels: weight_label,
		datasets: [
			{
				data: data_comisa,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_comisa_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_comisa_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
		],
	};

	const options_age_insomnia = {
		plugins: {
			legend: {
				// display: false
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"Insomnia risk depending on the age change":"나이에 따른 만성불면증 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Age (year)":"나이 (세)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};
	const options_age_osa = {
		plugins: {
			legend: {
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"OSA risk depending on the age change":"나이에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					font:{
						size:isMobile?10:16
					},
					display: true,
					text: count?"Age (year)":"나이 (세)",
					color: 'white'
				},
			},
		},
	};
	const options_age_comisa = {
		plugins: {
			legend: {
				labels: {
					filter: function(legendItem, data) {
						let label = data.datasets[legendItem.datasetIndex].label || '';
						console.log(label)
						if (label !== '') {

							return true;
						}
						return false;
					},
					color: 'white'
				}
			},
			title: {
				display: true,
				text: count?"COMISA risk depending on the age change":"나이에 따른 수면호흡장애 위험도 변화",
				font: {
					size: isMobile?18:24
				},
				color: 'white'
			},
		},
		scales: {
			yAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Risk (%)":"위험도 (%)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
				suggestedMin: 0,
				suggestedMax: 100,
			},
			xAxis: {
				ticks:{
					font:{
						size: isMobile?10:16
					},
					color: 'white'
				},
				title: {
					display: true,
					text: count?"Age (year)":"나이 (세)",
					font:{
						size:isMobile?10:16
					},
					color: 'white'
				},
			},
		},
	};

	const insomnia_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_insomnia_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_insomnia_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_insomnia_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_insomnia_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};
	const osa_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_osa_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_osa_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_osa_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_osa_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};
	const comisa_data_age = {
		labels: age_label,
		datasets: [
			{
				data: data_comisa_age,
				fill: false,
				borderColor: "yellow",
				lineTension: 0,
				backgroundColor: [
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"red",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
					"yellow",
				],
			},
			{
				data: data_comisa_age_std1,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				data: data_comisa_age_std2,
				fill: false,
				lineTension: 0,
				pointRadius: 0,
			},
			{
				label: count?"average risk":"나이별 평균 위험도",
				data: data_comisa_age_mean,
				backgroundColor: "white",
				lineTension: 0,
				pointRadius: 0,
				borderColor: "white",
			},
		],
	};

	const insomnia_eng = `Insomnia means symptoms rather than disease names. It's hard to fall asleep, wake up often, or wake up too early to fall asleep again. Or, it refers to symptoms that seem to have slept well, but are difficult to wake up the next morning and are tired. This causes difficulties in daytime activities, and if symptoms that are sleepy, tired, and difficult to concentrate persist more than three times a week or more for three months, it is diagnosed as chronic insomnia. More than 20% of the population have experienced insomnia more than once in their lives, and about 5% have chronic insomnia.
    The causes of chronic insomnia vary, and the causative diseases, symptoms, and damage situations vary depending on gender and age. Without proper treatment, physical symptoms such as cardiovascular disease, cerebrovascular disease, and metabolic disease and decline in memory can occur, depression and anxiety symptoms can occur or worsen. If you take sleeping pills for a long time or take health functional foods arbitrarily because you have insomnia without accurate diagnosis, secondary health harm may occur.
    The best way to prevent and improve insomnia is to maintain a regular bed-wake times, regardless of weekdays and weekends. When insomnia occurs, the habit of going to bed earlier than usual and lying down and waiting to fall asleep is very bad, even if you are not sleepy to compensate for sleep. Rather, by reducing the amount of time lying in bed, sleep is deepened and improves insomnia by less waking. No matter how much you haven’t slept, waking up at a fixed time the next day is the first step in overcoming insomnia.
    `
	const insomnia_kor = `	만성불면증은 잠들기 어렵거나, 자주 깨거나, 너무 일찍 깨어나 잠들기 어렵거나, 또는 잘 잔 것 같지만 다음날 아침에 일어나기 어렵고 피곤한 증상을 말합니다. 
	불면으로 인해 주간에 졸리거나 피곤하고 집중이 어려운 증상이 주 3회 이상, 3개월 이상 지속되면 만성불면증으로 진단합니다. 
	국내 인구의 20% 이상이 인생의 한 번 이상 불면을 경험하고, 약 5%는 만성불면증을 앓고 있습니다.

	만성불면증의 원인은 다양하며 성별과 연령에 따라 원인 질환과 증상 및 피해상황이 달라집니다. 
	적절한 치료를 받지 않을 경우, 심혈관계질환, 뇌혈관질환 및 대사성질환 같은 신체증상과 기억력저하가 생기고 우울증과 불안증상이 생기거나 악화될 수 있습니다. 
	정확한 진단 없이 수면제를 장기 복용하거나 건강기능식품 등을 임의로 복용할 경우 2차적인 건강 폐해가 발생할 수 있습니다. 
	
	만성불면증을 예방하고 개선할 수 있는 가장 좋은 방법은, 규칙적인 취침-기상시간을 주중/주말 관계없이 유지하는 것입니다. 
	잠을 보상하기 위해 졸리지 않아도 평소보다 일찍 잠자리에 들어가 누워서 잠들기만 기다리는 습관은 매우 좋지 않습니다. 
	오히려 침상에 누워있는 시간을 줄여야, 잠도 깊어지고 덜 깨서 불면이 호전됩니다. 
	아무리 잠을 못 자더라도 다음날 정해진 시간에 기상하는 것은 불면을 극복하는 첫번째 단계입니다.
	`
	const insomnia_text = count?insomnia_eng:insomnia_kor;

	const osa_eng = `Sleep breathing disorders include snoring, apnea, or ventilation difficulties. In particular, obstructive sleep apnea (OSA) is a chronic disease that occur snoring or respiratory disorders due to narrowing of the upper airway through which air flows during sleep. If you are obese or have a thick and short neck, snoring begins at a young age. As you get older, the muscle elasticity of upper airway decreases and if the airways continue to narrow and clog during sleep, you cannot breathe, and oxygen saturation in the body decreases. As the brain senses this, it gives strength to the diaphragm and chest muscles to breathe again, and the brain wakes up. It occurs hundreds times per every night. Most of the people with OSA may sleep without recognizing the awakening. However, when you wake up the next morning, you seem to have slept well, but your head is heavy and you feel tired during the day. Some people may not feel it at all, which prolongs your OSA and results in negative health consequences.
	The problem is that if hypoxia and frequent arousals from this OSA persist for years to decades, the risks of developing serious diseases such as high blood pressure, coronary artery disease, diabetes, stroke, or depression may increase. Even if the patient does not feel it himself, if snoring or breathing problems while sleeping are observed, active treatment should be provided.
	To prevent and improve OSA, you should abstain from drinking before bed, maintain an appropriate weight, and exercise regularly. If you lie on your side while sleeping, snoring may improve, but it is not a method that can be used for a long time. If you experience daytime fatigue, drowsiness, headache, etc., or are accompanied by metabolic diseases, you should consult a doctor and conduct a polysomnography to receive appropriate treatment.
	`
	const osa_kor = 
	`	수면호흡장애는 공기가 소통하는 상기도가 좁아져서 수면 중에 코골이나 호흡장애가 발생하는 만성질환입니다.

	비만이거나 목둘레가 굵고 짧은 경우 코골이가 젊어서부터 시작되는데, 나이가 들면서 상기도의 근육탄력성이 떨어지면 수면 중 기도가 좁아져 막히게 됩니다.
	이렇게 되면 숨을 쉬기 어려워 체내 산소포화도가 낮아지게 되는데, 이를 감지하여 횡경막과 가슴근육에 힘을 주어 숨을 쉬게 하면서 뇌가 깨어납니다.
	수면호흡장애 환자의 대부분은 이런 뇌의 각성을 자각하지 못하고 잠을 이어나갑니다.
	
	수면호흡장애 환자들은 아침에 잘 잤다고 느낌에도 머리가 무거워지고 낮에 피곤함을 느끼는 경우가 많습니다.
	어떤 환자들은 이런 증상을 느끼지 못해 장애가 장기화되는데, 이 경우 고혈압, 관상동맥질환, 당뇨병, 뇌졸중, 우울증과 같은 심각한 질환의 발생 위험이 높아집니다.
	따라서 환자 스스로는 문제를 인지하지 못하더라도, 코골이나 수면 중 호흡장애가 목격된다면 적극적인 치료가 필요합니다.
	
	수면호흡장애를 예방하고 개선하기 위해서는, 음주를 자제하고 적정 체중을 유지하며 규칙적인 운동을 해야 합니다.
	옆으로 누워 자면 코골이나 호흡장애가 개선되는 경우도 있지만, 장기적인 해결책은 아닙니다.
	주간에 피로감, 졸음, 두통 등의 증상이 있거나 대사성 질환과 관련이 있다면 의사와 상담하여 수면다원검사를 받아 적절한 치료를 시행해야 합니다.
	 
	`
	const osa_text = count?osa_eng:osa_kor

	const comisa_eng = `More than 45% of people who complain of insomnia are accompanied by OSA. In particular, postmenopausal women and men after the age of 50 complaining of insomnia are much more likely to be caused by OSA or accompanied by OSA. In this case, it is necessary to treat insomnia and OSA at the same time to improve sleep. Treatment methods may vary depending on the aspect of insomnia and the degree of OSA, which is an area that requires professional diagnosis and treatment by a physician.`;
	const comisa_kor = `불면증을 호소하는 사람의 45% 이상에서 수면호흡장애를 동반합니다. 
	특히 폐경기 이후 여성과 만 50세 이후 남성에서 불면을 호소하는 경우, 수면호흡장애로 인해 발생했거나 혹은 수면호흡장애를 동반하는 경우가 훨씬 많습니다. 
	이 경우 불면증과 수면호흡장애를 동시에 치료해야 수면개선 효과를 보입니다. 
	불면의 양상, 수면호흡장애 정도에 따라 치료 방법이 다르며, 이는 전문적인 진단과 치료가 필요한 영역입니다.`
	const comisa_text = count?comisa_eng:comisa_kor

  const insomnia_element = 
  isMobile?
  (
	<div id="container">
	  <Line options={options_weight_insomnia} data={insomnia_data_weight} style={{paddingLeft:"10px", paddingRight:"10px"}} />
	  <br/>
	  <p style={{marginBottom:"15px"}}>{insomnia_graph_text1}
	  </p>
	  <Line options={options_age_insomnia} data={insomnia_data_age} style={{paddingLeft:"10px", paddingRight:"10px"}} />
	  <p style={{marginBottom:"15px"}}>{insomnia_graph_text}</p>
      <h1 className="h1" style={{fontSize:"30px"}}>{count?"INSOMNIA":"만성불면증"}</h1>
      <br/>
      <p style={{marginBottom:"100px", width:"75%", fontSize:"14px"}} className="p">{insomnia_text}</p>
    </div>
  )
  :
  (
    <div id="container">
      <div id="left1" >
        <Line options={options_weight_insomnia} data={insomnia_data_weight} />
      </div>
      <div id="right1">
        <Line options={options_age_insomnia} data={insomnia_data_age} />
      </div>
	  <div id="left1" >
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{insomnia_graph_text1}
	  </p>
	  </div>
	  <div id="right1">
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{insomnia_graph_text}</p>
	  </div>
	  {/* <center><p style={{marginBottom:"20px"}}>*the yellow line indicates the average risk of each age group with the same gender</p></center> */}

      <h1 className="h1">{count?"INSOMNIA":"만성불면증 "}</h1>
      <br/>
      <pre style={{paddingBottom:"100px", width:"75%"}} className="p">{insomnia_text}</pre>
    </div>
  )

  const osa_element = 
  isMobile?
  (
	<div id="container">
      <Line options={options_weight_osa} data={osa_data_weight} style={{paddingLeft:"10px", paddingRight:"10px"}} /> 
      <p style={{marginBottom:"15px"}}>{osa_graph_text1}</p>
	  <Line options={options_age_osa} data={osa_data_age} style={{paddingLeft:"10px", paddingRight:"10px"}} />
      <p style={{marginBottom:"15px"}}>{osa_graph_text}</p>
	  <h1 className="h1" style={{fontSize:"30px"}}>{count?"OSA (Obstructive sleep apnea)":"수면호흡장애 (폐쇄성 수면 무호흡증)"}</h1>
      <br/>
      <p style={{marginBottom:"100px", width:"75%", fontSize:"14px"}} className="p">{osa_text}</p>
    </div>
  )
  :
  (
    <div id="container">
      <div id="left1">
      <Line options={options_weight_osa} data={osa_data_weight} /> 
      </div>
      <div id="right1">
      <Line options={options_age_osa} data={osa_data_age} />
      </div>
	  <div id="left1" >
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{osa_graph_text1}
	  </p>
	  </div>
	  <div id="right1">
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{osa_graph_text}</p>
	  </div>
      <h1 className="h1">{count?"OSA (Obstructive sleep apnea)":"수면호흡장애 (폐쇄성 수면 무호흡증)"}</h1>
      <br/>
      <pre style={{paddingBottom:"100px", width:"75%"}} className="p">{osa_text}</pre>
    </div>
  )

  const comisa_element = 
  isMobile?
  (
	<div id="container">
      <Line options={options_weight_comisa} data={comisa_data_weight} style={{paddingLeft:"10px", paddingRight:"10px"}}/>
      <p style={{marginBottom:"15px"}}>{comisa_graph_text1}</p>
	  <Line options={options_age_comisa} data={comisa_data_age} style={{paddingLeft:"10px", paddingRight:"10px"}} />
	  <p style={{marginBottom:"15px"}}>{comisa_graph_text}</p>
	  <center><p>*the yellow line indicates the average risk of each age group with the same gender</p></center>

	  <h1 className="h1" style={{fontSize:"28px"}}>{count?"COMISA (Comorbid insomnia and sleep apnea)":"수면호흡장애를 동반한 불면증"}</h1>
      <br/>
      <p style={{marginBottom:"100px", width:"75%", fontSize:"14px"}} className="p">{comisa_text}</p>
    </div>
  )
  :
  (
    <div id="container">
      <div id="left1">
      <Line options={options_weight_comisa} data={comisa_data_weight} />
      </div>
      <div id="right1">
      <Line options={options_age_comisa} data={comisa_data_age} />
      </div>
	  <div id="left1" >
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{comisa_graph_text1}
	  </p>
	  </div>
	  <div id="right1">
	  <p style={{marginBottom:"50px", fontSize: "18px"}}>{comisa_graph_text}</p>
	  </div>
      <h1 className="h1">{count?"COMISA (Comorbid insomnia and sleep apnea)":"수면호흡장애를 동반한 불면증"}</h1>
      <br/>
      <p style={{paddingBottom:"100px", width:"75%"}} className="p">{comisa_text}</p>
    </div>
  )


  return (
	<div style={{backgroundColor: 'black', color:'white', paddingBottom:"50px"}}>
	<a style={{margin:"20px", fontSize:"20px"}} onClick={() => navigate(-1)} class="a_1 previous round">
		&#8249;
	  </a>
    <center>
    <div style={{width:"100%"}}>
      
      {id == 1? insomnia_element : id == 3? osa_element : comisa_element}
      
    </div>
    </center>
	</div>
  );
}
