import React from "react";
import "./static/spinner.css";
import {isMobile} from 'react-device-detect';


export default function LoadingSpinner() {
  const count = JSON.parse(localStorage.getItem('count'));
  return (
    <div className="spinner-container">
      <div className="loading-spinner">
      </div>
      <p style={{paddingLeft: "20%", paddingRight: "20%", marginTop:"30px", textAlign: "justify", marginBottom: "300px"}}>{count?
      `Surprised by how simple it is?  
      Using machine learning to analyze polysomnography results from 
      more than 5,000 people, researchers developed an algorithm that can predict the 
      risk of the most common sleep disorders - sleep apnea, chronic insomnia, and insomnia with sleep breathing disorders - using a simple questionnaire. 
      The study was published in "Predicting the Risk of Sleep Disorders using a Machine Learning-Based Simple Questionnaire: Development and Validation Study, 
      Journal of Medical Internet Research (2023)".
      If SLEEPS predicts the risk of ‘OSA’ as 90%, there is a 90% chance that OSA will be detected when actual polysomnography is performed.`
      :`너무 간단해서 놀라셨나요?  
      5000여명의 수면다원 검사 결과를 기계학습을 통해서 분석하여 간단한 질문만으로도 가장 흔한 수면 질환인, 수면 무호흡장애, 만성불면증, 
      수면호흡장애를 동반한 불면증의 위험도를 예측할 수 있는 AI 알고리즘을 개발하였습니다.  이 연구는 "Predicting the Risk of Sleep Disorders using a 
      Machine Learning-Based Simple Questionnaire: Development and Validation Study, Journal of Medical Internet Research (2023)" 에 출판되었습니다. 
      SLEEPS가 ‘수면호흡장애’의 위험도를 90%로 예측했다면, 실제 수면다원검사를 시행했을 때, 수면호흡장애가 발견될 확률이 90%임을 의미합니다`
      }</p>
      <br/>
      
    </div>
  );
}